import React from 'react';

const ResultTestTelegrammIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.96267 2.17778e-05C5.84741 0.00989308 3.82216 0.857104 2.32993 2.35633C0.837705 3.85555 -2.30327e-05 5.88474 4.74957e-10 8.00002C4.7496e-10 10.1218 0.842855 12.1566 2.34315 13.6569C3.84344 15.1572 5.87827 16 8 16C10.1217 16 12.1566 15.1572 13.6569 13.6569C15.1571 12.1566 16 10.1218 16 8.00002C16 5.87829 15.1571 3.84346 13.6569 2.34317C12.1566 0.842877 10.1217 2.17778e-05 8 2.17778e-05C7.98756 -7.25927e-06 7.97511 -7.25927e-06 7.96267 2.17778e-05ZM11.2707 4.81602C11.3373 4.81469 11.4847 4.83136 11.5807 4.90936C11.6445 4.96475 11.6851 5.04207 11.6947 5.12602C11.7053 5.18802 11.7187 5.33002 11.708 5.44069C11.588 6.70602 11.0667 9.77536 10.8013 11.192C10.6893 11.792 10.4687 11.9927 10.2547 12.012C9.79067 12.0554 9.438 11.7054 8.988 11.4107C8.284 10.9487 7.886 10.6614 7.20267 10.2107C6.41267 9.69069 6.92467 9.40402 7.37467 8.93736C7.49267 8.81469 9.53933 6.95269 9.57933 6.78402C9.584 6.76269 9.58867 6.68402 9.542 6.64269C9.49533 6.60136 9.426 6.61536 9.376 6.62669C9.30533 6.64269 8.18067 7.38669 6.002 8.85669C5.682 9.07669 5.39333 9.18336 5.134 9.17669C4.84867 9.17136 4.29933 9.01602 3.89067 8.88336C3.38933 8.72002 2.99133 8.63402 3.026 8.35736C3.044 8.21336 3.24267 8.06602 3.62133 7.91536C5.95333 6.89936 7.508 6.22936 8.28667 5.90602C10.508 4.98202 10.97 4.82136 11.2707 4.81602Z'
        fill='#717784'
      />
    </svg>
  );
};

export default ResultTestTelegrammIcon;
