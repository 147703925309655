import TextField from 'shared/TextField/TextField';
import RegisterFormContainer from '../RegisterFormContainer';
import Button from 'shared/Button/Button';
import { FormikProps } from 'formik';
import React from 'react';
import DatePicker from '../../../../shared/DatePicker/DatePicker';
import { capitalize } from 'lodash';
import { minDate } from '_constants/minDate';
import classes from './RegistrationStepUserInfo.module.scss';

export const RegistrationStepUserInfo = ({ formik }: { formik: FormikProps<any> }) => {
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = capitalize(value);
    formik.setFieldValue(name, capitalizedValue);
  };

  return (
    <RegisterFormContainer className={classes.root}>
      <h1 className={classes.title}>Регистрация</h1>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name={'last'}
          label={
            <>
              Фамилия<span className='text-red-500'>*</span>
            </>
          }
          formik={formik}
          onChange={handleNameChange}
        />
        <TextField
          name={'first'}
          label={
            <>
              Имя<span className='text-red-500'>*</span>
            </>
          }
          formik={formik}
          onChange={handleNameChange}
        />
        <TextField name={'middle'} label={'Отчество'} formik={formik} onChange={handleNameChange} />
        <TextField
          name={'phone'}
          mask={'phone'}
          label={
            <>
              Номер телефона<span className='text-red-500'>*</span>
            </>
          }
          formik={formik}
        />
        <DatePicker
          name={'birthDate'}
          label={
            <>
              Дата рождения<span className='text-red-500'>*</span>
            </>
          }
          formik={formik}
          minDate={minDate}
          maxDateMessage={''}
          minDateMessage={''}
        />
        <Button fullWidth type='submit' disabled={formik.isSubmitting || !formik.isValid}>
          Продолжить
        </Button>
      </form>
      <p className={classes.acceptText}>
        Нажимая на кнопку Продолжить, вы автоматически соглашаетесь на 
        <a href='/Политика 17-11-2023.pdf' target='_blank'>
          обработку персональных данных
        </a>
      </p>
    </RegisterFormContainer>
  );
};
