import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderIcon from 'svgComponents/LoaderIcon';
import queryString from 'query-string';
import { LkContext } from 'context/LkContext/LkContext';
import { errorHandler } from 'utils/errorHandler';
import { useStyles } from './style';

const AuthTeachbase = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { teachbaseAuthHandler } = useContext(LkContext);

  let queryStringSearch = queryString.parse(search, { arrayFormat: 'index' });

  useEffect(() => {
    (async function () {
      try {
        const data = await teachbaseAuthHandler(queryStringSearch);
        const url = queryString.stringifyUrl({
          url: queryStringSearch.redirect_url,
          query: data,
        });
        window.location.href = url;
      } catch (err) {
        switchLocation();
        errorHandler(err);
        throw err;
      }
    })();
  }, []);

  return (
    <div className={classes.AuthTeachbase}>
      <LoaderIcon className={classes.loader} color={'#2787F5'} />
    </div>
  );
};

export default AuthTeachbase;

const switchLocation = (search) => {
  switch (window.location.host) {
    case 'tvoyhod.online':
      window.location.href = 'твойход.онлайн';
      return;
    case 'твойход.онлайн':
      window.location.href = 'tvoyhod.online';
  }
};
