import React from 'react';
import { restoreNewPassword } from 'api/AuthApi';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { doRequest } from 'hooks/doRequest';
import TextField from 'shared/TextField/TextField';
import Button from 'shared/Button/Button';
import classes from './steps.module.scss';

export const PasswordEnter = ({ saveToRequestData, nextStep, email, code }) => {
  const formik = useFormik({
    initialValues: {
      password: '',
      repPassword: '',
    },
    validationSchema: yup.object().shape({
      password: yup.string().min(8, 'Минимальная длина пароля 8 символов').required(),
      repPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
        .required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    const { success } = await doRequest(restoreNewPassword, {
      email,
      code,
      new_password: formik.values.password,
    });

    if (success) {
      saveToRequestData({
        password: formik.values.password,
        repPassword: formik.values.repPassword,
      });
      nextStep();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className={classes.title}>Восстановление пароля</h1>
      <TextField
        name='password'
        formik={formik}
        type='password'
        label='Новый пароль'
        error={formik.values.password && formik.errors.password}
        className={classes.input}
      />
      <TextField
        name='repPassword'
        formik={formik}
        label='Подтвердите новый пароль'
        type='password'
        error={formik.values.repPassword && formik.errors.repPassword}
        className={classes.input}
      />
      <Button
        fullWidth
        type='submit'
        size={'small'}
        disabled={!formik.isValid || !formik.values.password}
        className={classes.button}
      >
        Продолжить
      </Button>
    </form>
  );
};
