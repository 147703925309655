import React from 'react';

const ColorLineKodMinusIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='9.80616' cy='10.2168' r='9.41456' fill='white' />
      <rect
        x='14.3066'
        y='9.7168'
        width='1'
        height='9'
        transform='rotate(90 14.3066 9.7168)'
        fill='#39DEC8'
      />
    </svg>
  );
};

export default ColorLineKodMinusIcon;
