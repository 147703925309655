import React from 'react';
import { Box, Container } from '@material-ui/core';
import GeneralReport from 'components/_testComponents_legacy/GeneralReport/GeneralReport';

const PublicGeneralReport = () => {
  return (
    <Container id={'page'} maxWidth={'lg'}>
      <Box m={{ xs: '32px 0', md: '40px 0' }}>
        <GeneralReport />
      </Box>
    </Container>
  );
};

export default PublicGeneralReport;
