import React from 'react';
import { useColorLineKodItemBipolarStyles } from './style';
import classnames from 'classnames';
import {
  formatString,
  isBipolarSingleLabel,
} from 'components/_testComponents_legacy/ResultTvoiKod/_utils';
import { Box, useMediaQuery } from '@material-ui/core';

const removeSpecialCharsConfig = [
  {
    regex: /\(\+\)/,
    component: '',
  },
  {
    regex: /\(-\)/,
    component: '',
  },
];

export default function ColorKineKodItemBipolar(props) {
  const { item, color, variant, disableSingleLabel } = props;
  const classes = useColorLineKodItemBipolarStyles();
  c;
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  /** Флаг для отрисовки одного лейбла вместо двух */
  const isSingleLabel =
    !disableSingleLabel &&
    smDown &&
    isBipolarSingleLabel(item.title || item.title, item.titleMax || item.title);
  const singleLabel = formatString(item.titleMin, removeSpecialCharsConfig);

  const lineTitleClasses = classnames(classes.lineTitle, {
    [classes.lineTitleOutlined]: variant === 'outlined',
  });

  return (
    <>
      <div
        className={classnames(classes.fullLineColor, {
          [classes.colorRight]: item.value > 5.5,
          [classes.colorLeft]: item.value < 5.5,
        })}
        style={{
          background: color,
          boxShadow: `0 0 12px 0 ${color}`,
          width: item.value > 5.5 ? `${item.value * 10}%` : `${(10 - item.value) * 10}%`,
          left: item.value >= 5.5 ? 0 : 'auto',
          right: item.value < 5.5 ? 0 : 'auto',
        }}
      />

      <div className={`${classes.blockLine} ${classes.blockLeft}`}>
        <div
          className={classnames(classes.progressLeft, {
            [classes.progressLeftOutlined]: variant === 'outlined',
          })}
          style={{
            zIndex: item.value >= 5.5 ? 3 : -1,
          }}
        />
        <span className={classnames(lineTitleClasses, classes.leftTitle)}>
          {!isSingleLabel && formatString(item.titleMin || item.title)}
        </span>
      </div>

      {isSingleLabel && (
        <Box left={'24px'} className={classes.singleLabelSign}>
          {formatString('(-)')}
        </Box>
      )}

      <div
        className={classes.middle}
        style={{
          boxShadow: `0 0 12px 0 ${color}`,
          background: color,
        }}
      >
        {isSingleLabel && <Box className={lineTitleClasses}>{singleLabel}</Box>}
      </div>

      {isSingleLabel && (
        <Box right={'24px'} className={classes.singleLabelSign}>
          {formatString('(+)')}
        </Box>
      )}

      <div className={`${classes.blockLine} ${classes.blockRight}`}>
        <div
          className={classnames(classes.progressRight, {
            [classes.progressRightOutlined]: variant === 'outlined',
          })}
          style={{
            zIndex: item.value <= 5.5 ? 3 : -1,
          }}
        />
        <span className={classnames(lineTitleClasses, classes.rightTitle)}>
          {!isSingleLabel && formatString(item.titleMax || item.title)}
        </span>
      </div>
    </>
  );
}
