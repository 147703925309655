// @ts-nocheck
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import { theme } from 'shared/themeInstance';
import AppRoutes from 'AppRoutes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import './fonts/fonts.css';
import 'moment/locale/ru';
import TextExplosionEasterEgg from 'components/UbahEasterEgg/TextExplosionEasterEgg';
import { StylesProvider } from '@material-ui/styles';
import { useUserActivityLogger } from './hooks/useUserActivityLogger';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useReferralLinks } from './hooks/useReferralLinks';
import { useDarkMode } from './hooks/useDarkMode';
import { VisuallyImpariedModeProvider, VisuallyImpairedPanel } from 'features/visuallyImpairedMode';
import { CssBaseline } from '@material-ui/core';
import { useUTMCookie } from './hooks/useUTMCookie';
moment.locale('ru');

const App = () => {
  useDarkMode();
  useUserActivityLogger();
  useReferralLinks();
  useUTMCookie();

  const isAuthPages = window.location.href.includes('auth');

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
          <TextExplosionEasterEgg>
            <BrowserRouter>
              <VisuallyImpariedModeProvider>
                <VisuallyImpairedPanel />
                <CssBaseline />
                <AppRoutes />
                <ToastContainer
                  hideProgressBar
                  className={isAuthPages && 'authToastContainer'}
                  pauseOnFocusLoss={false}
                />
              </VisuallyImpariedModeProvider>
            </BrowserRouter>
          </TextExplosionEasterEgg>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default App;
