import { makeStyles } from '@material-ui/core';

export const useColorLineKodItemBipolarStyles = makeStyles((theme) => ({
  fullLineColor: {
    position: 'absolute',
    top: 0,
    height: '100%',
  },
  blockLine: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 54,
    position: 'relative',

    '&:last-child': {
      marginBottom: 0,
    },

    '&:nth-last-child(2)': {
      marginBottom: 0,
    },
  },
  middle: {
    display: 'flex',
    justifyContent: 'center',
    width: 10,
    height: 54,
    position: 'relative',
    borderRadius: 22,

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: -23,
      height: '100%',
      width: 'calc(100% + 46px)',
      background: 'inherit',
      zIndex: 11,
      borderRadius: 22,
      boxShadow: 'inherit',
    },
  },
  progressLeft: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
    background: '#3F3F3F',
  },
  progressLeftOutlined: {
    background: '#fff',
  },
  progressRight: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    background: '#3F3F3F',
  },
  progressRightOutlined: {
    background: '#fff',
  },
  lineTitle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Rg',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#fff',
    position: 'relative',
    zIndex: 17,

    '@media(max-width: 580px)': {
      fontSize: 12,
    },

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  lineTitleOutlined: {
    color: theme.brandColors.blackGray.main,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  rightTitle: {
    marginRight: 33,

    [theme.breakpoints.down('sm')]: {
      marginRight: 24,
    },
  },
  leftTitle: {
    marginLeft: 33,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 24,
    },
  },
  blockLeft: {
    justifyContent: 'flex-start',
  },
  colorRight: {
    borderRadius: '0 22px 22px 0',
  },
  colorLeft: {
    borderRadius: '22px 0 0 22px',
  },
  singleLabelSign: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 20,
  },
}));
