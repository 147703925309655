import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchApplyEvent2022 } from '_redux/actions';
import { AuthenticationStatus } from '../_constants/authentication-status';
import { UserProfile } from '../_types/UserProfile';
import { RootState } from './store';

export interface AppSliceState {
  authenticationStatus: AuthenticationStatus;
  profile?: UserProfile;
  appToken?: string;
}

export const JWT_KEY = 'JWT';

const initialState: AppSliceState = {
  authenticationStatus: AuthenticationStatus.PENDING,
  profile: null,
  appToken: localStorage.getItem(JWT_KEY),
};

const appSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<Partial<AppSliceState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    /** Сохраняет токен */
    setAppToken: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;
      localStorage.setItem(JWT_KEY, action.payload.token);
      state.appToken = token;
    },
    /** Авторизует и сохраняет токен */
    authenticateWithToken: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;

      localStorage.setItem(JWT_KEY, action.payload.token);
      state.appToken = token;
      state.authenticationStatus = AuthenticationStatus.AUTHORIZED;
    },
    logout: (state) => {
      state.authenticationStatus = AuthenticationStatus.ANONYMOUS;
      state.appToken = null;
      state.profile = null;
      localStorage.removeItem(JWT_KEY);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplyEvent2022.fulfilled, (state) => {
      state.profile.is_contest2022_app = true;
    });
  },
});

export const selectAppState = (state: RootState) => state.app;

export default appSlice.reducer;
export const { setAppState, setAppToken, authenticateWithToken, logout } = appSlice.actions;
