import React, { memo } from 'react';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as CrossIcon } from './assets/cross.svg';
import { ReactComponent as ReplaceIcon } from './assets/replace.svg';
import { ReactComponent as UploadIcon } from './assets/upload.svg';
import { ReactComponent as CriteriasIcon } from './assets/criterias.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as QuestionMark } from './assets/question_mark.svg';
import { ReactComponent as AwardStar } from './assets/award_star.svg';
import { ReactComponent as LockIcon } from './assets/lock.svg';

export const ICONS = {
  download: DownloadIcon,
  cross: CrossIcon,
  replace: ReplaceIcon,
  upload: UploadIcon,
  criterias: CriteriasIcon,
  search: SearchIcon,
  questionMark: QuestionMark,
  awardStar: AwardStar,
  lockIcon: LockIcon,
} as const;

interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  Svg?: React.VFC<React.SVGProps<SVGSVGElement>>;
  title?: keyof typeof ICONS;
}

export const Icon = memo((props: IconProps) => {
  const { className, Svg, title, ...otherProps } = props;

  if (title) {
    const IconComponent = ICONS[title];
    return <IconComponent className={className} {...otherProps} />;
  } else {
    return <Svg className={className} {...otherProps} />;
  }
});
