import React from 'react';
import classes from './FooterMain.module.scss';
import {
  MASS_MEDIA_EMAIL,
  TECH_SUP_EMAIL,
  TECH_SUP_PHONE,
  TH_TELEGRAM_LINK,
  TH_VK_LINK,
} from '../../CONSTANTS';
import { yaTarget } from 'utils/yaTarget';
import { ReactComponent as VkIcon } from 'pages/LandingPage/assets/icons/vk-icon.svg';
import { ReactComponent as TgIcon } from 'pages/LandingPage/assets/icons/telegram-icon.svg';
import { Icon } from '../../shared/Icon/Icon';
import { ReactComponent as WhiteWithOrangeLogo } from 'images/WhiteWithOrangeLogo.svg';
import Typography from '../../shared/Typography/Typography';

const FooterMain = () => {
  return (
    <footer className={classes.root} id='footerSection'>
      <section>
        <div className={classes.footerContainer}>
          <div className={classes.logoContainer}>
            <Icon Svg={WhiteWithOrangeLogo} className={classes.WhiteWithOrangeLogo} />
            <div className={classes.corporateIdentity}>
              <a href='https://disk.yandex.ru/d/sfOGHdC3epZARQ' target='_blank'>
                Фирменный стиль проекта
              </a>
            </div>
          </div>
          <div className={classes.connectionBlock}>
            <div className={classes.forQuestionsBlock}>
              <h3>Для вопросов и обращений:</h3>
              <a href={`mailto:${TECH_SUP_EMAIL}`}>{TECH_SUP_EMAIL}</a>
              <br />
              <a href={`tel:${TECH_SUP_PHONE}`}>{TECH_SUP_PHONE}</a>
            </div>

            <div className={classes.contactsForSmi}>
              <h3>Контакты для СМИ:</h3>
              <a href={`mailto:${MASS_MEDIA_EMAIL}`}>{MASS_MEDIA_EMAIL}</a>
            </div>

            <nav className={classes.nav}>
              <Icon Svg={WhiteWithOrangeLogo} className={classes.WhiteWithOrangeLogoMobile} />
              <div>
                <div className={classes.socialMobile}>
                  <a target='_blank' href={TH_VK_LINK} onClick={() => yaTarget('bp-vk')}>
                    <Icon Svg={VkIcon} className={classes.vkIcon} />
                  </a>

                  <a target='_blank' href={TH_TELEGRAM_LINK} onClick={() => yaTarget('bp-vk')}>
                    <Icon Svg={TgIcon} className={classes.tgIcon} />
                  </a>
                </div>
                <ul className={classes.footerOptions}>
                  <li>
                    <a href='#aboutUsSection' className={classes.footerLink}>
                      О НАС
                    </a>
                  </li>
                  <li>
                    <a href='#whoCanBecomeSection' className={classes.footerLink}>
                      ДЛЯ КОГО
                    </a>
                  </li>
                  <li>
                    <a href='#footerSection' className={classes.footerLink}>
                      КОНТАКТЫ
                    </a>
                  </li>
                </ul>
              </div>
            </nav>

            <div className={classes.corporateIdentityRequisites}>
              <a href='https://disk.yandex.ru/d/sfOGHdC3epZARQ' target='_blank'>
                <Typography variant={'bodyS'}>Фирменный стиль проекта</Typography>
              </a>
            </div>
            <div className={classes.requisitesRosmolodesh}>
              <a href='https://fadm.gov.ru/feedback/requisites/'>Реквизиты росмолодёжи</a>
              <div className={classes.social}>
                <a target='_blank' href={TH_VK_LINK} onClick={() => yaTarget('bp-vk')}>
                  <Icon Svg={VkIcon} className={classes.vkIcon} />
                </a>

                <a target='_blank' href={TH_TELEGRAM_LINK} onClick={() => yaTarget('bp-vk')}>
                  <Icon Svg={TgIcon} className={classes.tgIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className={classes.links}>
          <a
            className={classes.downloadLink}
            target='_blank'
            href='/Пользовательское соглашение.pdf'
          >
            Пользовательское соглашение
          </a>
          <a className={classes.downloadLink} target='_blank' href='/Политика 17-11-2023.pdf'>
            Политика обработки персональных данных
          </a>
          <a className={classes.downloadLink} target={'_blank'} href={'ПОЛОЖЕНИЕ_ТВОЙ_ХОД.pdf'}>
            Положение
          </a>
          <a
            className={classes.downloadLink}
            target='_blank'
            href='/Методические_рекомендации_по_оценке_2024.pdf'
          >
            Методические рекомендации по оценке
          </a>
          <a
            className={classes.downloadLink}
            target='_blank'
            href='/Список победителей для сайта.pdf'
          >
            Победители трека Первопроходец
          </a>
        </div>
      </section>
      <section>
        <div className={classes.requisites}>
          <span>АНО «Большая Перемена» (ОГРН 1217700205766)</span>
        </div>
      </section>
    </footer>
  );
};

export default FooterMain;
