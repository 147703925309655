export const ROLES_SUMMARY_TEST_NAMES = {
  YOUR_CODE: 'tvoi-kod-student',
  YOUR_MOTIVATION: '20210423-212929-4580',
  YOUR_ROLES: '20210427-172238-3775',
  YOUR_CHOICE: '20210519-132201-3842',
};

export const ROLES_SUMMARY_SCHEMA = [
  {
    id: 0,
    title: 'Генератор идей',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S76',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S75',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S75',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S12',
        factor: 0.05,
      },
    ],
  },
  {
    id: 1,
    title: 'Исследователь ресурсов',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S77',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S76',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S76',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S13',
        factor: 0.05,
      },
    ],
  },
  {
    id: 2,
    title: 'Аналитик',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S78',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S77',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S77',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S14',
        factor: 0.05,
      },
    ],
  },
  {
    id: 3,
    title: 'Специалист',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S79',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S78',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S78',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S15',
        factor: 0.05,
      },
    ],
  },
  {
    id: 4,
    title: 'Душа компании',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S80',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S79',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S79',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S16',
        factor: 0.05,
      },
    ],
  },
  {
    id: 5,
    title: 'Мотиватор',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S81',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S80',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S80',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S17',
        factor: 0.05,
      },
    ],
  },
  {
    id: 6,
    title: 'Координатор',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S82',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S81',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S81',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S18',
        factor: 0.05,
      },
    ],
  },
  {
    id: 7,
    title: 'Реализатор',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S83',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S82',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S82',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S19',
        factor: 0.05,
      },
    ],
  },
  {
    id: 8,
    title: 'Контролер',
    testSchema: [
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CODE,
        path: 'S84',
        factor: 0.3,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_MOTIVATION,
        path: 'S83',
        factor: 0.2,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_ROLES,
        path: 'S83',
        factor: 0.45,
      },
      {
        testName: ROLES_SUMMARY_TEST_NAMES.YOUR_CHOICE,
        path: 'S20',
        factor: 0.05,
      },
    ],
  },
];
