import classes from './RegisterStepVkEmailConfirm.module.scss';
import React, { EventHandler } from 'react';
import RegisterFormContainer from '../RegisterFormContainer';
import Button from '../../../../shared/Button/Button';

export interface RegisterStepVkEmailConfirmProps {
  vkEmail: string;
  onCancel: EventHandler<any>;
  onSubmit: EventHandler<any>;
}

const RegisterStepVkEmailConfirm = (props: RegisterStepVkEmailConfirmProps) => {
  const { onSubmit, onCancel, vkEmail } = props;

  return (
    <RegisterFormContainer
      title={'В твоем аккаунте во ВКонтакте привязана почта:'}
      className={classes.root}
    >
      <p className={classes.mail}>{vkEmail}</p>
      <p className={classes.question}>Создать аккаунт с этой почтой?</p>

      <div className={classes.actions}>
        <Button fullWidth variant={'muiBold'} onClick={onCancel} className={classes.button}>
          Нет, использовать другую
        </Button>
        <Button fullWidth onClick={onSubmit}>
          Создать
        </Button>
      </div>
    </RegisterFormContainer>
  );
};

export default RegisterStepVkEmailConfirm;
