import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import { useVisuallyImpariedMode } from 'features/visuallyImpairedMode/provider';
import classes from './VisuallyImpairedSwitchButton.module.scss';

interface VisuallyImpairedSwitchButtonProps extends IconButtonProps {
  hideTextOnMobile?: boolean;
  className?: string;
}

export const VisuallyImpairedSwitchButton = (props: VisuallyImpairedSwitchButtonProps) => {
  const { hideTextOnMobile, className, ...otherProps } = props;
  const { toggle } = useVisuallyImpariedMode();

  return (
    <IconButton className={classes.iconButton} onClick={toggle} {...otherProps}>
      <VisibilityOutlined fontSize='medium' className={className} />
      {!hideTextOnMobile && (
        <span className={classes.visuallyImpairedLabel}>Версия для слабовидящих</span>
      )}
    </IconButton>
  );
};
