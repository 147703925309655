import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RegisterSliceState {
  vkEmail?: string;
  vkCode?: string;
  ambassadorReferralId?: string;
  returnUrl?: string;
}

const initialState: RegisterSliceState = {
  vkEmail: null,
  vkCode: null,
  ambassadorReferralId: null,
  returnUrl: '',
};

export const RegisterSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegisterSliceState: (state, action: PayloadAction<Partial<RegisterSliceState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setRegisterSliceState } = RegisterSlice.actions;
