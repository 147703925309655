import React, { Suspense, memo, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import QrComponent from 'pages/QrComponent/QrComponent';
import FooterMain from 'components/FooterMain/FooterMain';
import AuthTeachbase from 'pages/AuthTeachbase/AuthTeachbase';
import PublicGeneralReport from 'pages/PublicGeneralReport/PublicGeneralReport';
import PreAccelerationAuth from 'pages/PreAccelerationAuth/PreAccelerationAuth';
import { Box } from '@material-ui/core';
import AuthPage from 'pages/AuthPage/AuthPage';
import Navbar from 'components/Navbar/Navbar';
import { useAuthentication } from 'hooks/useAuthentication';
import { ScreenLoader } from 'shared/ScreenLoader';
import { useUrlSearchParams } from 'hooks/useUrlSearchParams';
import { useDispatch } from 'react-redux';
import { setTaskId } from '_redux/drugoeDelo/drugoeDelo.slice';
import { PARTNER_KEY } from 'entities/partner';

const LkPage = React.lazy(() => import('./pages/LkPage/LkPage'));
const AdminPage = React.lazy(() => import('./pages/AdminPage/AdminPage'));
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const PublicTestResult = React.lazy(() => import('./pages/PublicTestResult/PublicTestResult'));

const RedirectToPdf = () => {
  useEffect(() => {
    window.location.href = '/winners.pdf';
  }, []);

  return <ScreenLoader fullScreen />;
};

const AppRoutes = () => {
  useAuthentication();

  const location = useLocation();
  const [searchParams] = useUrlSearchParams<{ dd_task_id: string; partner: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (searchParams.dd_task_id) {
      dispatch(setTaskId(Number(searchParams.dd_task_id!)));
    }
    if (searchParams.partner) {
      sessionStorage.setItem(PARTNER_KEY, searchParams.partner);
    }
  }, []);

  return (
    <Suspense fallback={<ScreenLoader fullScreen />}>
      <Routes>
        <Route path='/' element={<LandingPage />} />

        <Route
          path='auth/*'
          element={
            <Box display='flex' flex={1} justifyContent='center' overflow='auto'>
              <AuthPage />
            </Box>
          }
        />

        {/* Редирект на файл winners.pdf */}
        <Route path='/winners' element={<RedirectToPdf />} />

        <Route path='lk/*' element={<LkPage />} />
        <Route path='admin/*' element={<AdminPage />} />
        <Route path='qr' element={<QrComponent />} />
        <Route path='teachbase_auth' element={<AuthTeachbase />} />
        <Route path='preinc_auth' element={<PreAccelerationAuth />} />

        <Route
          path='results/*'
          element={
            <div className='child-first:bg-black-gray'>
              <Navbar />
              <Routes>
                <Route path=':result_id' element={<PublicTestResult />} />
                <Route path='summary/:user_id' element={<PublicGeneralReport />} />
              </Routes>
              <FooterMain />
            </div>
          }
        />

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Suspense>
  );
};

export default memo(AppRoutes);
