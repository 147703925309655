import React from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../_redux/store';
import { NightsStay, WbSunny } from '@material-ui/icons';
import { setDarkMode } from '../../_redux/darkMode/darkMode.slice';

const DarkModeButton = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((r: RootState) => r.darkMode);

  const handleClick = () => {
    dispatch(setDarkMode(!darkMode));
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        {darkMode ? <WbSunny className={'text-white'} /> : <NightsStay className={'text-white'} />}
      </IconButton>
    </div>
  );
};

export default DarkModeButton;
