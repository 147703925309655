import React from 'react';

const ResultTestShareVkIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.456 0H5.544C1.06133 0 0 1.06133 0 5.544V10.456C0 14.9387 1.06133 16 5.544 16H10.456C14.9387 16 16 14.9387 16 10.456V5.544C16 1.06133 14.9273 0 10.456 0ZM12.9173 11.4153H11.7547C11.3147 11.4153 11.1787 11.0653 10.388 10.264C9.69933 9.59733 9.39467 9.50733 9.22533 9.50733C8.988 9.50733 8.92 9.57533 8.92 9.90267V10.9527C8.92 11.2353 8.83 11.4047 8.08467 11.4047C6.854 11.4047 5.48733 10.6593 4.528 9.27C3.08267 7.238 2.68667 5.71333 2.68667 5.39733C2.68667 5.228 2.75467 5.07 3.082 5.07H4.24467C4.538 5.07 4.65133 5.20533 4.76467 5.52133C5.34 7.18133 6.3 8.638 6.69533 8.638C6.842 8.638 6.91 8.57 6.91 8.198V6.48067C6.86467 5.69 6.44667 5.62267 6.44667 5.34067C6.44667 5.20467 6.56 5.06933 6.74 5.06933H8.56933C8.818 5.06933 8.908 5.20467 8.908 5.498V7.81333C8.908 8.06133 9.02133 8.152 9.08867 8.152C9.23533 8.152 9.36 8.06133 9.63067 7.79067C10.4667 6.85333 11.0647 5.408 11.0647 5.408C11.144 5.23867 11.2793 5.08067 11.5733 5.08067H12.736C13.086 5.08067 13.1653 5.26067 13.086 5.50933C12.9393 6.18733 11.5167 8.19667 11.5167 8.19667C11.3927 8.4 11.3473 8.49 11.5167 8.71667C11.6407 8.886 12.0473 9.236 12.3187 9.552C12.8153 10.1167 13.1987 10.5907 13.3007 10.9187C13.414 11.2453 13.244 11.4147 12.9167 11.4147L12.9173 11.4153Z'
        fill='#717784'
      />
    </svg>
  );
};

export default ResultTestShareVkIcon;
