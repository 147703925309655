export class TestUtils {
  static SUMMARY_REQUIRED_TESTS = [
    'tvoi-kod-student',
    'tvoia-pozitsia-student',
    'tvoia-logika',
    '20210427-172238-3775',
    '20210423-212929-4580',
    '20210519-132201-3842',
  ];

  static extractData(test) {
    return {
      name: test?.result.metadata.testTitle.replace(/(\(S\)|Student)/g, '').trim(),
      barsDiagrams:
        test?.result.variants[0].report.blocks.find((i) => i.type === 'barsDiagram').blocks || [],
      scalesText:
        test?.result.variants[0].report.blocks.find((i) => i.type === 'scalesTexts').texts || [],
    };
  }

  static getHeadlines(tests, config = []) {
    const headlines = [];
    for (let testName of Object.keys(tests)) {
      const { name, barsDiagrams, scalesText } = TestUtils.extractData(tests[testName]);

      const testHeadlines = {
        name,
        testName,
        diagrams: [],
      };

      for (let barsDiagram of barsDiagrams) {
        const { scales } = barsDiagram;

        for (let diagramItemKey of Object.keys(scales)) {
          const { value, bipolar } = scales[diagramItemKey];
          const configItem = config.find((c) => c.testName === testName);
          const headlinesDiagramItem = {
            diagramData: scales[diagramItemKey],
            scalesText: scalesText[diagramItemKey],
          };

          // Фильтр диаграмм на основе конфига, либо дефолтных порогов
          if (configItem) {
            const { ignoreDiagrams = [], minValue } = configItem;
            if (!ignoreDiagrams.some((k) => k === diagramItemKey) || value >= minValue) {
              testHeadlines.diagrams.push(headlinesDiagramItem);
            }
          } else {
            if (bipolar) {
              if (value <= 3.5 || value >= 7.5) {
                testHeadlines.diagrams.push(headlinesDiagramItem);
              }
            } else {
              if (value >= 7.5) {
                testHeadlines.diagrams.push(headlinesDiagramItem);
              }
            }
          }
        }
      }

      headlines.push(testHeadlines);
    }

    return headlines;
  }

  static summaryAllowed(tests) {
    return !this.SUMMARY_REQUIRED_TESTS.some((testName) => tests[testName] === undefined);
  }
}
