import React, { useContext, useMemo } from 'react';
import { LkContext } from 'context/LkContext/LkContext';
import { RolesSummary } from 'models/RolesSummary/RolesSummary';
import BrandTypography from 'shared/BrandTypography/BrandTypography';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const COLORS = ['#39DEC8', '#F15053', '#9CADE5'];

const useStyles = makeStyles((theme) => ({
  colorLine: {
    width: 6,
    marginRight: 16,
  },
}));

const GeneralReportRoles = (props) => {
  const { testScores = [] } = props;

  const classes = useStyles();

  return (
    <Box>
      {testScores.map((testScore, i) => (
        <Box key={i}>
          <Box display={'flex'} alignItems={'stretch'}>
            <Box className={classes.colorLine} style={{ background: COLORS[i % COLORS.length] }} />

            <BrandTypography fontSize={'18px'}>
              <b>{testScore.title}</b>
            </BrandTypography>
          </Box>

          <Box mt={'16px'}>{testScore.desc}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default GeneralReportRoles;
