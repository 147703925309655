import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { EducationLevel } from '../_constants/education-level';
import { ListResponse } from '../_types/ListResponse';
import { Region } from '../_types/Region';
import { Country } from '_types/Country';
import { logout } from '_redux/appSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('JWT');

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithAuthLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithAuthLogout,
  tagTypes: [
    'Profile',
    'PartnerEvent',
    'Internship',
    'activeExpertTrackSurveySubmission',
    'trackSurveyExpertScores',
    'trackActExpertScores',
    'TrackActAppeal',
    'TrackSurveyAppeal',
    'DistrictStage',
    'Portfolio',
    'ForumAreas',
    'AdventCalendar',
    'Survey',
    'TrackAct',
    'Course',
    'Achievements',
    'Ambassador',
    'Test',
    'AdminEdu',
    'AdminUser',
    'AdminTeamList',
    'AdminTeamInfo',
    'AdminApplicationInfo',
    'RewardList',
    'Regionlist',
    'TrackBlazerInfo',
    'TrackBlazerTaskInfo',
    'TrackBlazerTestPassing',
    'TrackActInfo',
    'TrackActTestPassing',
    'TrackBlazerScoresInfo',
    'TrackActScoresInfo',

    'ExpertApi',
    'HeadExpertApi',
  ],
  endpoints: (build) => ({
    regions: build.query<ListResponse<Region>, void>({
      query: () => '/region-list',
    }),
    guilds: build.query<
      any,
      {
        regionId: number;
        educationLevel: EducationLevel;
      }
    >({
      query: ({ regionId, educationLevel }) =>
        `/educational-institutions-from-region-level/${regionId}/${educationLevel}`,
    }),
    countries: build.query<{ list: Country[]; rf_id: number }, void>({
      query: () => '/territory/countries',
    }),
    /** Это костыльное значение которое надо подставлять в educational_institution если выбраны школьники */
    guildPlaceholder: build.query<any, void>({
      query: () => `/educational-institutions-from-region-level/15/1`,
      transformResponse(data: any) {
        return data?.list?.[0];
      },
    }),
  }),
});

export const {
  // Список регионов
  useRegionsQuery,
  // Список образовательных учреждений по региону и уровню образования
  useGuildsQuery,
  useCountriesQuery,
  useGuildPlaceholderQuery,
} = baseApi;
