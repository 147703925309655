import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  oneResult: {
    marginBottom: 24,
  },
  oneResultVal: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  valueData: {
    width: 6,
    height: 39,
    background: '#EFEFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginRight: 16,
  },
  valueTitle: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#1B1B1B',
    position: 'relative',
    textTransform: 'lowercase',
    display: 'inline-block',
    zIndex: 3,

    '& strong': {
      fontFamily: 'Inter',
      fontWeight: 'bold',
      fontSize: 18,
      color: '#1B1B1B',
      position: 'relative',
      textTransform: 'lowercase',
      display: 'inline-block',
      zIndex: 3,

      '@media(max-width: 580px)': {
        fontSize: 16,
      },

      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
  colorLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '130%',
    color: '#717784',
    marginTop: 0,
    marginBottom: 0,

    '& br': {
      display: 'none',
    },

    '& strong': {
      display: 'none',
    },

    '& p': {
      '& br': {
        display: 'block',
        content: "''",
        marginBottom: 16,
      },
    },
  },
}));

export default function DescriptionsResultTestItem(props) {
  const { item, color } = props;
  const classes = useStyles();

  return (
    <div className={classes.oneResult}>
      <div className={classes.oneResultVal}>
        <div className={classes.valueData}>
          <div
            className={classes.colorLine}
            style={{
              background: color,
            }}
          />
        </div>
        <span
          className={classes.valueTitle}
          dangerouslySetInnerHTML={{
            __html: getTitle(item),
          }}
        />
      </div>

      <div className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: item.content }} />
    </div>
  );
}

const getTitle = (item) => {
  if (!item) {
    return '';
  }

  const isSame = item.header === item.footer;

  if (item.footer?.length && item.header?.length) {
    return item.level === 3
      ? isSame
        ? item.header
        : item.header + ' или ' + item.footer
      : item.level < 3
      ? item.footer
      : item.header;
  }

  return (item.header?.length && item.header) || (item.footer?.length && item.footer);
};
