import { Config } from '@vkontakte/superappkit';
import { isLocalhost } from './api/instance';

const VK_APP_ID_PRODUCTION = 8105067;
const VK_APP_ID_TEST = 8112736;

Config.init({
  appId: isLocalhost ? VK_APP_ID_TEST : VK_APP_ID_PRODUCTION,
  // loginDomain: 'login.vk.ru',
  // oauthDomain: 'oauth.vk.ru',
  // connectDomain: 'id.vk.ru',
});
