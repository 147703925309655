import classNames from 'classnames';
import { VISUALLY_IMPAIRED_MODE_CLASS } from 'features/visuallyImpairedMode/constants';
import { Color } from 'features/visuallyImpairedMode/types';
import classes from './VisuallyImpairedColors.module.scss';
import { useVisuallyImpariedMode } from 'features/visuallyImpairedMode/provider';

export const VisuallyImpairedColors = () => {
  const { setConfig, config } = useVisuallyImpariedMode();

  const renderButtons = () => {
    return Object.values(Color).map((color) => {
      return (
        <button
          key={color}
          className={classNames(`${VISUALLY_IMPAIRED_MODE_CLASS}-letter-btn`, color, classes.btn, {
            [classes.active]: config.color === color,
          })}
          onClick={() => setConfig('color', color)}
        >
          Ц
        </button>
      );
    });
  };

  return <div className={classes.root}>{renderButtons()}</div>;
};
