import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import classnames from 'classnames';
import { xs } from 'shared/themeInstance';

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => theme.brandColors[props.color]?.main || props.color,
    fontSize: (props) => props.size,
    lineHeight: 1.2,
  },
  pageTitle: {
    fontSize: 32,
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: 27,
    },
  },
  description: {
    fontWeight: 300,
    fontSize: 21,
  },
  description2: {
    fontSize: 22,
    '& b': {
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 64,
    [theme.breakpoints.down(770)]: {
      fontSize: 46,
    },
    [theme.breakpoints.down(550)]: {
      fontSize: 28,
    },
  },
  title1: {
    fontSize: 48,
    fontWeight: 900,
    [xs]: {
      fontSize: 22,
    },

    '& *': {
      fontWeight: 900,
    },
  },
}));

export default function BrandTypography(props) {
  const { variant, color, size, fontSize, className, BoxProps, ...rest } = props;

  const classes = useStyles(props);

  return (
    <Box clone fontSize={fontSize} {...BoxProps}>
      <Typography
        variant={'inherit'}
        className={classnames(className, classes.root, classes[variant])}
        {...rest}
      />
    </Box>
  );
}
