import React from 'react';
import App from 'App';
import GlobalContext from 'HOC/GlobalContext/GlobalContext';
import './yupConfig';
import './vkConfig';
import './App.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import './i18n';

import 'features/visuallyImpairedMode/styles/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GlobalContext>
    <App />
  </GlobalContext>,
);
