import { UserRoleId } from '../_types/UserRoleId';
import { UserProfile } from '../_types/UserProfile';

export class PermissionUtils {
  static hasRole(roleId: UserRoleId, user?: UserProfile) {
    return Boolean(user?.role?.find((r) => r.role_id === roleId));
  }

  static hasRoles(roleIds: UserRoleId[], user?: UserProfile) {
    return Boolean(roleIds.every((roleId) => user?.role.find((ur) => ur.role_id === roleId)));
  }
}
