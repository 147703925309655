import classes from './RegisterStepEmailVk.module.scss';
import React from 'react';
import RegisterFormContainer from '../RegisterFormContainer';
import TextField from '../../../../shared/TextField/TextField';
import { FormikProps } from 'formik';
import Button from '../../../../shared/Button/Button';

export interface RegisterStepEmailVkProps {
  formik: FormikProps<any>;
}

const RegisterStepVkEmailEnter = (props: RegisterStepEmailVkProps) => {
  const { formik } = props;

  return (
    <RegisterFormContainer
      className={classes.root}
      title={'Упс! У тебя не указана почта ВКонтакте.'}
    >
      <form onSubmit={formik.handleSubmit}>
        <p>Укажи её здесь и присоединяйся к сообществу «Твой Ход»</p>

        <div>
          <TextField name={'email'} formik={formik} label={'Электронная почта'} />
        </div>

        <div className={classes.actions}>
          <Button fullWidth size={'small'} type={'submit'} disabled={!formik.isValid}>
            Продолжить
          </Button>
        </div>
      </form>
    </RegisterFormContainer>
  );
};

export default RegisterStepVkEmailEnter;
