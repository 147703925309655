import React from 'react';

const ResultTestWhatsappIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.6481 9.58803C11.4501 9.48869 10.4761 9.01003 10.2948 8.94336C10.1128 8.87736 9.98075 8.84469 9.84809 9.04336C9.71675 9.24136 9.33675 9.68736 9.22142 9.81936C9.10609 9.95203 8.99009 9.96803 8.79209 9.86936C8.59409 9.76936 7.95542 9.56069 7.19875 8.88603C6.61009 8.36069 6.21209 7.71203 6.09675 7.51336C5.98142 7.31536 6.08475 7.20803 6.18342 7.10936C6.27275 7.02069 6.38209 6.87803 6.48075 6.76269C6.58009 6.64669 6.61275 6.56403 6.67942 6.43136C6.74542 6.29936 6.71275 6.18403 6.66275 6.08469C6.61275 5.98536 6.21675 5.01003 6.05209 4.61336C5.89075 4.22736 5.72742 4.28003 5.60609 4.27336C5.47948 4.26832 5.35279 4.2661 5.22609 4.26669C5.09409 4.26669 4.87942 4.31603 4.69809 4.51469C4.51675 4.71269 4.00475 5.19203 4.00475 6.16736C4.00475 7.14203 4.71475 8.08403 4.81342 8.21669C4.91275 8.34869 6.21075 10.35 8.19809 11.208C8.67075 11.412 9.03942 11.534 9.32742 11.6247C9.80209 11.776 10.2341 11.7547 10.5748 11.7034C10.9554 11.6467 11.7468 11.224 11.9121 10.7614C12.0774 10.2987 12.0774 9.90203 12.0274 9.81936C11.9781 9.73669 11.8461 9.68736 11.6474 9.58803H11.6481ZM8.03342 14.5234H8.03075C6.8506 14.5235 5.69214 14.2062 4.67675 13.6047L4.43609 13.462L1.94209 14.1167L2.60742 11.6847L2.45075 11.4354C1.79105 10.3849 1.44205 9.16915 1.44409 7.92869C1.44475 4.29536 4.40142 1.33936 8.03609 1.33936C9.79609 1.33936 11.4508 2.02603 12.6948 3.27136C13.3085 3.88244 13.795 4.60918 14.1261 5.40952C14.4571 6.20987 14.6262 7.06792 14.6234 7.93403C14.6214 11.5674 11.6654 14.5234 8.03342 14.5234ZM13.6421 2.32536C12.9075 1.58598 12.0336 0.999719 11.0708 0.600561C10.108 0.201403 9.07564 -0.0027175 8.03342 2.73184e-05C3.66342 2.73184e-05 0.106753 3.55669 0.104753 7.92803C0.104753 9.32536 0.469419 10.6894 1.16342 11.8914L0.0380859 16L4.24142 14.8974C5.40381 15.5306 6.70638 15.8625 8.03009 15.8627H8.03342C12.4028 15.8627 15.9601 12.306 15.9621 7.93403C15.9653 6.89221 15.7619 5.86009 15.3637 4.89738C14.9655 3.93467 14.3803 3.06047 13.6421 2.32536Z'
        fill='#717784'
      />
    </svg>
  );
};

export default ResultTestWhatsappIcon;
