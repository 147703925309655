import { makeStyles } from '@material-ui/styles';

export const useGeneralReportStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: '20.8px',
    '& p': {
      marginBottom: 16,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButtons: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  savePdfBtn: {
    width: 160,
    marginLeft: 8,
  },
  rolesWrap: {
    marginTop: 29,
    padding: '23px 26px',
    background: theme.brandColors.blackGray.main,
    borderRadius: 22,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '23px 16px',
      marginTop: 32,
    },
  },
  rolesTitleWrap: {
    marginBottom: 16,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
            "logo title"
            "logo desc"
        `,
    [theme.breakpoints.down('sm')]: {
      gridTemplateAreas: `
                "logo title"
                "desc desc"
            `,
    },
  },
  rolesTitleLogo: {
    gridArea: 'logo',
    maxWidth: 190,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 90,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  rolesTitle: {
    gridArea: 'title',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 11,
  },
  rolesTitleDesc: {
    gridArea: 'desc',
    marginTop: 8,
    marginLeft: 11,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      marginLeft: 0,
    },
  },
}));
