import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { LightenDarkenColor } from 'utils/lightenDarkenColor';
import classnames from 'classnames';
import LoaderIcon from 'svgComponents/LoaderIcon';
import { CheckedIcon } from 'svgComponents/CheckedIcon';
import { BrandButtonCheckbox } from 'shared/BrandButton/BrandButtonCheckbox';
import { important } from 'utils/cssImportant';

const disabledColor = '#8d8d8d';
const disabledBackgroundColor = '#d8dfe8';
const disabledShadowColor = '#c2c6cd';

const useButtonStyles = makeStyles((theme) => ({
  root: {
    boxShadow: (props) => {
      const darkenColor = LightenDarkenColor(
        theme.brandColors[props.variant || 'brandRed'].main,
        -20,
      );
      return `6px 6px 0px ${darkenColor}, 4px 4px 0px ${darkenColor}, 2px 2px 0px ${darkenColor}`;
    },
    width: (props) => props.width || props.w,
    height: (props) => props.height || props.h,
    textTransform: 'none',
    transition: '.3s',
    position: 'relative',
    color: '#fff',
    fontWeight: 600,
    padding: '19px 22px',
    zIndex: '2',
    border: 'none',
    borderRadius: '22px',
    backgroundColor: (props) => `${theme.brandColors[props.variant || 'brandRed'].main} !important`,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe shared"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe shared Emoji"',
      '"Segoe shared Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: (props) =>
        `${LightenDarkenColor(theme.brandColors[props.variant || 'brandRed'].main, 10)} !important`,
    },
    '&:active': {
      // boxShadow: 'none',
      backgroundColor: (props) =>
        `${LightenDarkenColor(theme.brandColors[props.variant || 'brandRed'].main, 10)} !important`,
    },
    '&:focus': {
      // boxShadow: props => {
      //     const darkenColor = LightenDarkenColor(theme.brandColors[props.variant || 'brandRed'].main, -200)
      //     return `5px 5px 0px ${darkenColor}, 3px 3px 0px ${darkenColor}, 1px 1px 0px ${darkenColor}`
      // },
    },
    '& span': {
      zIndex: 2,
      position: 'relative',
    },
    '&:before': {
      content: (props) => (props.disableGlow ? '' : "''"),
      width: '94%',
      height: '94%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      filter: 'blur(12px)',
      background: (props) => theme.brandColors[props.variant || 'brandRed'].main,
    },
  },
  disabled: {
    color: disabledColor,
    backgroundColor: `${disabledBackgroundColor} !important`,
    boxShadow: `
            6px 6px 0px ${disabledShadowColor},
            4px 4px 0px ${disabledShadowColor},
            2px 2px 0px ${disabledShadowColor} !important`,
    '&:before': {
      display: 'none',
    },
  },
  sizeSmall: {
    fontSize: `14px !important`,
    padding: '14px 19px !important',
  },
  sizeLarge: {
    padding: '32px 22px !important',
    fontSize: '24px !important',
  },
  checkedPrimary: {
    color: theme.palette.blackGray.main,
    backgroundColor: important(theme.palette.brandGray.main),
    boxShadow: important(
      '5.47368px 5.47368px 0px #C3C8CD, 3.64912px 3.64912px 0px #C3C8CD, 1.82456px 1.82456px 0px #C3C8CD',
    ),
  },
  disabledLoading: {
    '& svg *': {
      fill: theme.palette.brandRed.main,
    },
  },
}));

/**
 * @deprecated
 * use <Button /> instead
 */
const BrandButton = (props, ref) => {
  const { loading, children, variant, disableGlow, checkbox, checked, ...other } = props;

  const classes = useButtonStyles(props);
  const { checkedPrimary, disabledLoading, ..._classes } = classes;
  const _classNames = classnames({
    [props.className]: true,
    [classes.root]: true,
    [classes.checkedPrimary]: checkbox && checked,
    [classes.disabledLoading]: loading && props.disabled,
  });

  let startIcon;

  if (loading) {
    startIcon = <LoaderIcon className={'loader'} color={'#E0E0E0'} />;
  } else if (checkbox) {
    startIcon = <BrandButtonCheckbox checked={checked} />;
  }

  return (
    <Button className={_classNames} classes={_classes} ref={ref} startIcon={startIcon} {...other}>
      <span>{children}</span>
    </Button>
  );
};

export default React.forwardRef(BrandButton);
