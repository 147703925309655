import classes from './VKAuthButton.module.scss';
import React from 'react';
import Button, { ButtonProps } from '../../shared/Button/Button';
import classnames from 'classnames';
import { ReactComponent as VkIcon } from 'images/vk_round_icon.svg';
import { Connect, VKAuthSuccessResult } from '@vkontakte/superappkit';
import { useAuthorizeVkUserMutation } from '../../_services/authApi';
import { toast } from 'react-toastify';
import { VK_API_VERSION } from '../../CONSTANTS';
import { useDispatch } from 'react-redux';
import { setRegisterSliceState } from '../../_redux/register/register.slice';
import { authenticateWithToken, setAppState, setAppToken } from '../../_redux/appSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticationStatus } from '../../_constants/authentication-status';
import clsx from 'clsx';

const VKAuthButton = (props: ButtonProps) => {
  const { className, children = 'Войти через VK ID', ...buttonProps } = props;
  const [triggerAuth] = useAuthorizeVkUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const handleClick = async () => {
    try {
      const vkResult = (await Connect.userVisibleAuth({})) as VKAuthSuccessResult;
      const authResult = await triggerAuth({
        silentToken: vkResult.payload.token,
        uuid: vkResult.payload.uuid,
        v: VK_API_VERSION,
      }).unwrap();

      if (authResult.token) {
        dispatch(
          authenticateWithToken({
            token: authResult.token,
          }),
        );

        navigate(urlParams.get('returnUrl') || '/lk');
      } else {
        dispatch(
          setRegisterSliceState({
            vkCode: authResult.code,
            vkEmail: authResult.email,
          }),
        );

        navigate(`/auth/signup${search}`);
      }
    } catch {
      toast('Произошла ошибка авторизации', { type: 'error' });
    }
  };

  return (
    <Button
      className={clsx(className, classes.root)}
      fullWidth
      startIcon={<VkIcon />}
      onClick={handleClick}
      color='blue'
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default VKAuthButton;
