import React, { useState } from 'react';
import classes from './Navbar.module.scss';
import { ReactComponent as WhiteWithRedLogo } from 'images/white-with-red-logo.svg';
import { Button, IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import classnames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import { useDialog } from 'hooks/useDialog';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../_redux/store';
import { AuthenticationStatus } from '../../_constants/authentication-status';
import { PermissionUtils } from '../../models/PermissionUtils';
import { UserRoleId } from '../../_types/UserRoleId';
import { useProfileQuery } from '../../_services/usersApi/usersApi';
import DarkModeButton from '../DarkModeButton/DarkModeButton';
import { useCheatCode } from '../../hooks/useCheatCode';
import {
  VisuallyImpairedSwitchButton,
  useVisuallyImpariedMode,
} from 'features/visuallyImpairedMode';
import NavbarDialog from 'components/Header/HeaderDialog';

const Navbar = () => {
  const navigate = useNavigate();
  const { toggle, dialogProps } = useDialog();
  const { authenticationStatus } = useSelector((rootState: RootState) => rootState.app);
  const userProfile = { ...useProfileQuery().data };
  const isAdmin = PermissionUtils.hasRole(UserRoleId.ADMIN, userProfile);
  const { enabled } = useCheatCode('dm');

  const visuallyImapairedMode = useVisuallyImpariedMode();

  const [state, setState] = useState({
    timeoutId: null,
  });

  const handleLoginMouseUp = () => {
    if (state.timeoutId) {
      navigate('/auth/signin');
    }

    clearTimeout(state.timeoutId);
    setState({ ...state, timeoutId: null });
  };

  const handleLoginMouseDown = () => {
    clearTimeout(state.timeoutId);

    setState({
      ...state,
      timeoutId: setTimeout(() => {
        navigate('/admin');
      }, 1000),
    });
  };

  const handleLinkClick = () => {
    toggle(false);
  };

  return (
    <div className={classnames(classes.root)}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrap}>
          <WhiteWithRedLogo />
          {enabled && <DarkModeButton />}
        </div>

        <div className={classes.desktopActions}>
          {!visuallyImapairedMode.isActive && (
            <div className='text-white'>
              <VisuallyImpairedSwitchButton color='inherit' />
            </div>
          )}

          <a href={'https://news.tvoyhod.online/'} target={'_blank'}>
            <Button className={classes.btn}>Новости</Button>
          </a>

          {authenticationStatus === AuthenticationStatus.AUTHORIZED ? (
            <>
              {isAdmin && (
                <Link to={'/admin'}>
                  <Button className={classes.btn}>Панель администратора</Button>
                </Link>
              )}

              {!isAdmin && (
                <Link to={'/lk'}>
                  <Button className={classes.btn} startIcon={<AccountCircle />}>
                    Личный кабинет
                  </Button>
                </Link>
              )}
            </>
          ) : (
            <>
              <Button
                className={classnames(classes.btn, classes.btnContained)}
                onMouseUp={handleLoginMouseUp}
                onMouseDown={handleLoginMouseDown}
              >
                Вход
              </Button>

              <Link to={'/auth/signup'}>
                <Button
                  className={classnames(
                    classes.btn,
                    classes.btnContained,
                    classes.btnContained_reversed,
                  )}
                >
                  Регистрация
                </Button>
              </Link>
            </>
          )}
        </div>

        <div className={classes.mobileActions}>
          {!visuallyImapairedMode.isActive && (
            <div className='text-white'>
              <VisuallyImpairedSwitchButton
                className={classes.visuallyImpairedIcon}
                hideTextOnMobile={true}
                color='inherit'
              />
            </div>
          )}
          <IconButton className={classes.iconBtn} onClick={() => toggle(true)}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </div>
      </div>

      <NavbarDialog {...dialogProps} onLinkClick={handleLinkClick} />
    </div>
  );
};

export default Navbar;
