import React from 'react';
import { Box, Button } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '14px 32px',
    color: '#717784',
    fontSize: 16,
    textTransform: 'none',
  },
}));

const PrintButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      onClick={() => window.print()}
      classes={{
        root: classes.root,
        ...props.classes,
      }}
      endIcon={<SaveAltIcon />}
      {...props}
    >
      Распечатать
    </Button>
  );
};

export default PrintButton;
