import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { combineClasses } from 'utils/combineClasses';
import classnames from 'classnames';
import { xs } from 'shared/themeInstance';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },
  title: {
    fontFamily: 'Grandis Extended',
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 900,
    color: '#313131',
    marginBottom: '24px',
    textTransform: 'uppercase',
    [xs]: {
      fontSize: '16px',
      lineHeight: '17px',
    },
  },
}));

const RegisterFormContainer = (props) => {
  const { title } = props;
  const classes = combineClasses(props.classes, useStyles());

  return (
    <div className={classnames(classes.root, props.className)}>
      {title && <h1 className={classes.title}>{title}</h1>}

      {props.children}
    </div>
  );
};

export default RegisterFormContainer;
