import React, { useMemo, useState } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { DateMask, PhoneMask, CodeMask, CustomMask, PassportMask } from 'utils/Masks';
import { Fieldset } from 'shared/Fieldset';
import { combineClasses } from 'utils/combineClasses';
import classnames from 'classnames';
import { FormikUtils } from 'models/FormikUtils/FormikUtils';
import BrandButton from 'shared/BrandButton/BrandButton';
import { useFileInput } from 'hooks/useFileInput';
import { toast } from 'react-toastify';
import { xs } from 'shared/themeInstance';

const _getTextFieldHeight = (props) => {
  switch (props.size) {
    case 'large':
      return 69;
    default:
      return 54;
  }
};

const _getFontSize = (props) => {
  switch (props.size) {
    case 'large':
      return 24;
    default:
      return 16;
  }
};

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '2px solid #e2e2e2',
    verticalAlign: 'middle',
    padding: '16px 0',
    height: _getTextFieldHeight,
    fontSize: _getFontSize,
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:not(:focused):not(:palceholder-show)': {
      backgroundColor: '#E8F0FE',
      border: 'none',
    },
    '&.MuiInputBase-multiline': {
      height: 'auto',
    },
    '& textarea': {
      padding: '0 16px',
    },
    '& input': {
      height: 51,
      padding: '10px 16px',
      boxSizing: 'border-box',
      textTransform: (props) => props.capitalize && 'capitalize',
      // Если поле ввода пустое и textDecoration = underline, то будет маленькая лишняя полоска
      '&:not([value=""])': {
        textDecoration: (props) => props.underline && 'underline',
      },
    },
    '&~ .MuiFormHelperText-root': {
      display: (props) => (props.displayErrorLabel ? 'flex' : 'none'),
      width: '100%',
      fontFamily: 'Inter',
      fontSize: 12,
      marginTop: 8,
      color: '#EF4444',
    },
    '& ::placeholder': {
      opacity: 1,
      color: 'currentColor',
    },
  },
  error: {
    border: `2px solid #EF4444 !important`,
    '&.Mui-focused': {
      background: '#fff !important',
      border: '2px solid #e2e2e2 !important',
    },
  },
  disabled: {
    backgroundColor: '#e1e1e1',
  },
  fileInput: {
    '& input': {
      padding: '10px 228px 10px 16px',
      [xs]: {
        padding: '10px 16px',
      },
    },
  },
}));

const UIKitTextField = React.forwardRef((props, ref) => {
  const { underline, capitalize, inputProps, type, displayErrorLabel, ...rest } = props;
  const { fileInput, ...classes } = useStylesReddit(props);
  const inputClasses = combineClasses(classes, props.classes);
  const className = classnames(props.className, { [classes.fileInput]: type === 'file' });

  return (
    <MuiTextField
      {...rest}
      className={className}
      ref={ref}
      type={type === 'file' ? 'text' : type}
      inputProps={{
        autoComplete: 'off',
      }}
      InputProps={{
        ...inputProps,
        classes: inputClasses,
        disableUnderline: true,
      }}
    />
  );
});

/** Возвращает стилизованный TextField из UIKit
 * - [Макет](https://www.figma.com/file/lWb5HgPQxCoJbenzsoMGFk/%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B0%D1%8F-%D0%BF%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B0?node-id=647%3A1529)
 *
 *
 * Кастомные пропсы:
 *
 * @param {Boolean} code - Добавляет расстояние между символами и увеличивает их размер. [Пример](https://www.figma.com/file/lWb5HgPQxCoJbenzsoMGFk/%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B0%D1%8F-%D0%BF%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B0?node-id=646%3A8695)
 * @param {Boolean} phoneMask - Добавляет маску для телефона. [Пример](https://www.figma.com/file/lWb5HgPQxCoJbenzsoMGFk/%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B0%D1%8F-%D0%BF%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B0?node-id=646%3A7080)
 * @param {String} type - Тип поля. При 'password' - добавляет кликабельный глазик в конце. [Пример](https://www.figma.com/file/lWb5HgPQxCoJbenzsoMGFk/%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B0%D1%8F-%D0%BF%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B0?node-id=646%3A8855)
 *
 * Поддерживает все пропсы TextField от Material-shared
 * - [TextField](https://material-ui.com/api/text-field/#props)
 *
 */

const useStyles = makeStyles((theme) => ({
  counterContainer: {
    position: 'absolute',
    bottom: '1ch',
    right: '1ch',
    fontSize: 16,
  },
  counterContainerPrimary: {
    color: '#c4c4c4',
  },
  fileButton: {
    position: 'absolute',
    right: 0,
    width: 216,
    [xs]: {
      width: '100%',
      position: 'static',
      marginTop: 16,
    },
  },
  subtitle: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 'normal',
    color: '#A0A0A0',
  },
}));

const defaultFormikOptions = {
  alwaysDisplayErrors: false,
};

/**
 * @deprecated
 */
const TextField = React.forwardRef(
  (
    {
      formik,
      name,
      mask,
      code,
      phoneMask,
      date,
      type,
      label,
      subtitle,
      showCounter = false,
      counterLimit = 0,
      fieldsetProps,
      formikOptions,
      inputProps,
      accept,
      fileSize,
      passport,
      displayErrorLabel = true,
      ...params
    },
    ref,
  ) => {
    const classes = useStyles();
    let ips = {};
    formikOptions = {
      ...defaultFormikOptions,
      ...formikOptions,
    };

    const { alwaysDisplayErrors } = formikOptions;

    const [newType, setNewType] = useState((type === 'file' ? 'text' : type) || 'text');
    const [empty, setEmpty] = useState(() => !!params?.value || !!params?.defaultValue);
    const formikValue = FormikUtils.nameAccessor(formik?.values, name);
    const formikError = FormikUtils.nameAccessor(formik?.errors, name);
    const formikTouched = FormikUtils.nameAccessor(formik?.touched, name);
    let error = params.error || ((formikTouched || alwaysDisplayErrors) && formikError);
    let helperText = params.helperText || ((formikTouched || alwaysDisplayErrors) && formikError);
    const fileInput = useFileInput();

    let value;

    if (type === 'file') {
      value = params.value || formikValue?.name || '';
    } else {
      value = params.value || formikValue;
    }

    if (mask) {
      ips = { ...ips, inputComponent: CustomMask(mask) };
    }

    if (phoneMask) {
      Object.assign(ips, {
        inputComponent: PhoneMask,
      });
    }

    if (date) {
      Object.assign(ips, {
        inputComponent: DateMask,
      });
    }

    if (code) {
      Object.assign(ips, {
        inputComponent: CodeMask,
      });
    }

    if (passport) {
      Object.assign(ips, {
        inputComponent: PassportMask,
      });
    }

    const handleBlur = (e) => {
      if (type === 'file') {
        if (params.onBlur) {
          params.onBlur(e);
        } else if (formik) {
          const ce = {
            target: {
              name,
              value: formikValue,
            },
          };

          formik.handleBlur(ce);
        }
      } else {
        params.onBlur && params.onBlur(e);
        formik?.handleBlur && formik.handleBlur(e);
        setEmpty(!!e?.target?.value);
      }
    };

    const handleChange = (e) => {
      if ((counterLimit && e.target.value.length > counterLimit) || type === 'file') {
        return;
      }

      if (formik) {
        formik.handleChange(e);
      } else {
        console.log(e);
        params.onChange && params.onChange(e);
      }
    };

    const handleFileDialogOpen = () => {
      fileInput.selectFile();
    };

    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        const { name, size } = e.target.files[0];
        const allowedFormats = accept?.split(',');
        const fileFormat = '.' + name.split('.').slice(-1);

        if (fileSize && size > fileSize) {
          toast('Размер файла превышает установленный лимит', { type: 'error' });
        } else {
          if (params.onChange) {
            params.onChange(e);
          } else if (formik) {
            const ce = __getCustomFileEvent(e);
            formik.handleChange(ce);
          }
        }
      }
    };

    const counterContainerClassNames = useMemo(
      () =>
        classnames({
          [classes.counterContainer]: true,
          [classes.counterContainerPrimary]: true,
        }),
      [],
    );

    const __getCustomFileEvent = (fileInputEvent) => {
      return {
        target: {
          name,
          value: fileInputEvent.target.files[0],
        },
      };
    };

    return (
      <Fieldset title={label} {...fieldsetProps}>
        <UIKitTextField
          name={name}
          value={value}
          onChange={handleChange}
          error={error}
          helperText={helperText}
          inputProps={{ inputProps, ...ips }}
          displayErrorLabel={displayErrorLabel}
          type={type}
          data-notempty={empty}
          data-code={code ?? false}
          ref={ref}
          onBlur={handleBlur}
          {...params}
        />

        {showCounter && (
          <div className={counterContainerClassNames}>
            {value?.length || 0}/{counterLimit}
          </div>
        )}

        {type === 'file' && (
          <>
            <BrandButton
              disableGlow
              h={47}
              className={classes.fileButton}
              variant={'brandGreen'}
              onClick={handleFileDialogOpen}
            >
              Загрузите файл
            </BrandButton>

            <input
              hidden
              type={'file'}
              name={name}
              accept={accept}
              ref={fileInput.inputRef}
              onChange={handleFileChange}
            />
          </>
        )}

        {subtitle && <h6 className={classes.subtitle}>{subtitle}</h6>}
      </Fieldset>
    );
  },
);

export default TextField;
