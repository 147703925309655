import React, { ReactNode, useMemo } from 'react';
import classes from './TextField.module.scss';
import {
  FormControl,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import classnames from 'classnames';
import { FormikProps } from 'formik';
import { getMaskedInput, PhoneMaskedInput, DateMaskedInput } from './MaskedInput';
import InputLabel from '../InputLabel/InputLabel';
import { FormikUtils } from '../../models/FormikUtils/FormikUtils';
import InputHelperText from '../InputHelperText/InputHelperText';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
  // TODO: нужно переименовать вариант 'admin', так как он используется не только в админке
  variant?: 'default' | 'admin' | 'outlined';
  formik?: FormikProps<any>;
  mask?: 'phone' | 'date' | unknown[];
  showCounter?: boolean;
  counterLimit?: number;
  value?: string;
  labelRequired?: boolean;
  labelHelperText?: ReactNode | string;
  withoutFormikUtils?: boolean;
  bottomSlot?: React.ReactNode;
  readOnly?: boolean;
  isNumeric?: boolean;
};

const TextField = (props: TextFieldProps) => {
  const {
    variant = 'default',
    formik,
    mask,
    helperText,
    error,
    className,
    name,
    value,
    label,
    labelHelperText,
    labelRequired,
    showCounter,
    counterLimit,
    placeholder,
    withoutFormikUtils,
    onChange,
    fullWidth,
    readOnly = false,
    ...muiTextFieldProps
  } = props;

  let _value;

  if (formik) {
    _value = FormikUtils.nameAccessor(formik?.values, name);
  } else {
    _value = value;
  }

  let formikErrorHelperText;

  if (withoutFormikUtils) {
    const meta = formik.getFieldMeta(name);
    formikErrorHelperText = meta.touched && meta.error;
  } else {
    formikErrorHelperText =
      FormikUtils.nameAccessor(formik?.touched, name) &&
      FormikUtils.nameAccessor(formik?.errors, name);
  }

  const _className = classnames(className, classes.common, {
    [classes.default]: variant === 'default',
    [classes.second]: variant === 'admin',
    [classes.outlined]: variant === 'outlined',
    ['3']: error || formikErrorHelperText,
    [classes.error]: error || formikErrorHelperText,
  });

  const _placeholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    } else {
      switch (mask) {
        case 'date':
          return '__.__.____';
        case 'phone':
          return '+7(___) ___ - __ - __';
      }
    }
  }, [placeholder, mask]);

  const inputComponent = useMemo(() => {
    if (!mask || muiTextFieldProps.multiline) {
      return;
    }

    switch (mask) {
      case 'date':
        return DateMaskedInput;
      case 'phone':
        return PhoneMaskedInput;
      default:
        return getMaskedInput(mask);
    }
  }, []);

  const handleChange = (e) => {
    if (props.isNumeric && !/^[\d\s]*$/.test(e.target.value)) {
      return;
    }

    const { value } = e.target;
    if (counterLimit && value.length > counterLimit) return;

    if (onChange) {
      onChange(e);
    } else {
      formik?.handleChange(e);
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
    ];

    if (!allowedKeys.includes(e.key) && !/[\d\s]/.test(e.key) && props.isNumeric) {
      e.preventDefault();
    }
  };

  return (
    <FormControl fullWidth={fullWidth} className={_className}>
      <div className={'flex flex-col'}>
        <MuiTextField
          name={name}
          value={_value}
          placeholder={_placeholder}
          className={classes.tf}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={formik?.handleBlur}
          label={
            label && (
              <InputLabel
                variant={variant}
                required={labelRequired}
                label={label}
                helperText={labelHelperText}
              />
            )
          }
          fullWidth={fullWidth}
          {...muiTextFieldProps}
          InputProps={{
            readOnly: readOnly,
            inputComponent,
            ...muiTextFieldProps.InputProps,
            classes: {
              multiline: 'px-4 py-5 !h-auto',
              ...muiTextFieldProps?.InputProps?.classes,
              inputMultiline: classnames(
                'classes.inputMultiline',
                muiTextFieldProps?.InputProps?.classes?.inputMultiline,
              ),
            },
          }}
        />

        {showCounter && (
          <div className={classes.counter}>
            <span>
              {_value?.length || 0}/{counterLimit}
            </span>
          </div>
        )}
      </div>

      {(helperText || formikErrorHelperText) && (
        <div className={classes.errorText}>
          <InputHelperText error={error}>{helperText || formikErrorHelperText}</InputHelperText>
        </div>
      )}
    </FormControl>
  );
};

export default TextField;
