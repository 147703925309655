import { instance } from 'api/instance';
import React, { useReducer, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { authReducer } from './AuthReducer';
import { doRequest } from 'hooks/doRequest';
import { MEMBERSHIP_TYPES, PARTICIPATION_TYPES, TEAM_LEAD_ROLE_ID } from 'CONSTANTS';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AuthenticationStatus } from '_constants/authentication-status';
import { setRegisterSliceState } from '_redux/register/register.slice';
import { useProfileQuery, usersApi } from '_services/usersApi/usersApi';
import { setAppState } from '_redux/appSlice';
import { baseApi } from '_services/baseApi';

const JvachBannerVisibleLSItemName = 'jvach_banner_visible';
let initialJvachBannerVisible = localStorage.getItem(JvachBannerVisibleLSItemName);

if (initialJvachBannerVisible === null) {
  initialJvachBannerVisible = 'true';
}

const defaultActivitiesProfile = {
  keyses: [],
  keys: undefined,
  team: undefined,
  users: [],
};

const AuthState = ({ children }) => {
  let clearTime;
  const reduxDispatch = useDispatch();
  const profileQuery = useProfileQuery();
  const [loadingSert, setLoadingSert] = React.useState(true);
  const initialState = {
    id: null,
    email: null,
    nickname: null,
    phone: null,
    birth_date: null,
    gender: undefined,
    format: undefined,
    role: undefined,
    team_role: undefined,
    roleRecommendations: [],
    activitiesInvites: [],
    school: {
      char: null,
      class: null,
      position: null,
    },
    name: {
      last: null,
      first: null,
      middle: null,
    },
    isAuth: AuthenticationStatus.PENDING,
    reward_score: 0,
    direction: undefined,
    directionActivities: [],
    activities_profile: defaultActivitiesProfile,
    tg_chat_id: undefined,
    jvachBannerVisible: initialJvachBannerVisible,
    invitation: false, // Наличие приглашений в команду для участника экосистемы,
    isFinishIter: false,
    disTeamMembers: [],
    teamPoints: [],
    is_excluded_team: false,
    cert: false,
    status: '',
    expert_score_info: undefined,
    third_stage_app: false, // Флаг регистрации на 3й этап
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (profileQuery.isSuccess && profileQuery.data) {
      dispatch({
        type: 'LOGINED',
        payload: profileQuery.data,
      });
    }

    if (!profileQuery.isSuccess) {
      dispatch({
        type: 'LOGIN_ERROR',
      });
    }
  }, [profileQuery.data, profileQuery.isSuccess]);

  const setState = (stateSlice) => {
    dispatch({ type: 'SET_STATE', payload: stateSlice });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT', payload: { ...initialState, isFinishIter: state.isFinishIter } });
    reduxDispatch(
      setAppState({
        authenticationStatus: AuthenticationStatus.ANONYMOUS,
      }),
    );

    reduxDispatch(
      usersApi.util.updateQueryData('profile', undefined, (profile) => {
        profile = null;
        return profile;
      }),
    );

    reduxDispatch(
      setRegisterSliceState({
        vkCode: null,
        vkEmail: null,
      }),
    );

    reduxDispatch(baseApi.util.resetApiState());
  };

  const generateCert = async () => {
    const res = await doRequest(() => {
      return instance.get('/api/certs/generate');
    });

    if (res.success) {
      toast('Сертификат успешно сгенерирован');
      setState({ cert: true });
    }
  };

  const getCert = async () => {
    const jwt = localStorage.getItem('JWT');
    window.open(`/api/certs/1/download?jwt=${jwt}&time-${new Date().getTime()}`, '_blank');
  };

  const login = async (data, { admin = false } = {}) => {
    const requestUrl = admin ? '/api/auth/admin_login' : '/api/auth/login';
    let response = await instance.post(requestUrl, { ...data });

    if (response?.data) {
      if (response?.data?.token) {
        localStorage.setItem('JWT', response.data.token);
      }
    } else {
      dispatch({ type: 'LOGIN_ERROR', payload: response.data });
    }

    return response.data;
  };

  const editUser = async (objData) => {
    try {
      console.log(objData);
      const res = await instance.post('/api/users/edit', objData);
      dispatch({ type: 'UPDATE_USER', payload: res.data });
    } catch (err) {
      throw err;
    }
  };

  const onChangeSearchField = (event, { setLoadingSearch, setState, params = {} }) => {
    if (clearTime) clearTimeout(clearTime);
    const { value } = event.target;

    clearTime = setTimeout(() => {
      if (value.length > 0) {
        setLoadingSearch(true);
        instance
          .get(`/api/educational-institutions-search`, {
            params: {
              q: value.trim(),
              ...params,
            },
          })
          .then((data) => {
            setLoadingSearch(false);
            let arr = [];
            if (!Array.isArray(data.data) && data.data.name.includes(value)) {
              arr.push(data.data);
            } else if (Array.isArray(data.data)) {
              arr = arr.concat(data.data);
              arr = arr.map((item) => {
                const title = `${item.name}, ${item.address.replace(/[\d]{6},*/g, '')}`;
                return {
                  id: item.id,
                  title,
                };
              });
            }

            setState((prev) => ({ ...prev, schoolOptions: arr }));
          })
          .catch((err) => {
            setLoadingSearch(false);
            setState((prev) => ({ ...prev, schoolOptions: [] }));
            throw err;
          });
      }
    }, 1000);
  };

  const checkingOldPassword = async (body) => {
    try {
      const res = await instance.post('/api/users/password/check-correct', body);
      return res.data.result;
    } catch (err) {
      throw err;
    }
  };

  const editPassword = async (body) => {
    try {
      await instance.post('/api/users/change-password', body);
    } catch (err) {
      throw err;
    }
  };

  const fetchActivities = async () => {
    const res = await doRequest(() => {
      return instance.get(`/api/team_activitie/keyses`);
    });

    const { data, success } = res;

    if (success) {
      setState({ directionActivities: [data.data.direction, ...data.data.all] });
    }

    return res;
  };

  const fetchActivitiesProfile = async () => {
    const res = await doRequest(
      () => {
        return instance.get('/api/team_activitie/cabinet');
      },
      {},
      null,
      { showError: false },
    );

    if (res.success) {
      const { users, task_solution, ...rest } = res.data.data;

      if (users) {
        setState({
          teamPoints: new Array(users?.length).fill(0),
        });
      }

      setState({
        activities_profile: {
          users,
          taskSolution: task_solution,
          ...rest,
        },
      });
    }

    return res;
  };

  const fetchSelectActivitiesTask = async (taskId) => {
    const { success } = await doRequest(() => {
      return instance.post(`/api/team_activitie/${taskId}/choose_keys`);
    });

    if (success) {
      await fetchActivitiesProfile();
    }
  };

  const fetchSelectActivitiesRole = async (roleId) => {
    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/${roleId}/change_team_role`);
    });

    if (res.success) {
      setState({
        team_role: roleId,
      });
      await fetchActivitiesProfile();
    }

    return res;
  };

  const fetchActivitiesInvites = async () => {
    const { data, success } = await doRequest(() => {
      return instance.get('/api/team_activitie/invites');
    });

    if (success) {
      setState({
        activitiesInvites: data.data,
      });
    }
  };

  const fetchEditTeam = async (name, directionId, description, isOpen, members) => {
    const _formData = new FormData();
    _formData.append('team[title]', name);
    _formData.append('team[description]', description);
    _formData.append('team[direction]', directionId);
    _formData.append('team[isAdd]', isOpen ? '1' : '0');

    for (let member of members) {
      _formData.append('team[inviteUsers][]', member.id);
    }

    const res = await doRequest(() => {
      return instance.post('/api/team_activitie/edit_team', _formData);
    });

    if (res.success) {
      await fetchActivitiesProfile();
      setState({ direction: directionId });
    }

    return res;
  };

  const fetchAcceptInvite = async (inviteId) => {
    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/${inviteId}/accept_invite`);
    });

    if (res.success) {
      _removeInvite(inviteId);
      setState({
        format: MEMBERSHIP_TYPES.MEMBER.id,
        activities_profile: {
          ...state.activities_profile,
          team: {
            id: 1, // dummy ID in between requests
          },
        },
      });
      await fetchActivitiesProfile();
    }

    return res;
  };

  const fetchDeclineInvite = async (inviteId) => {
    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/${inviteId}/reject_invite`);
    });

    if (res.success) {
      _removeInvite(inviteId);
      setState({ activities_profile: defaultActivitiesProfile, format: undefined });
    }

    return res;
  };

  const fetchTeamLeave = async () => {
    const res = await doRequest(() => {
      return instance.post('/api/team_activitie/leave_team');
    });

    if (res.success) {
      setState({
        format: undefined,
        activities_profile: defaultActivitiesProfile,
      });
    }

    return res;
  };

  const fetchSelectFormat = async (formatId) => {
    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/${formatId}/choose_format`);
    });

    let newTeamRole = state.team_role;
    if (state.format === MEMBERSHIP_TYPES.LEAD.id) {
      // Очищаем роль, если уходим с позиции капитана
      newTeamRole = undefined;
    } else {
      // Ставим капитанскую роль, если переходим на позицию капитана
      if (formatId === MEMBERSHIP_TYPES.LEAD.id) {
        newTeamRole = TEAM_LEAD_ROLE_ID;
      }
    }

    if (res.success) {
      setState({
        team_role: newTeamRole,
        format: formatId,
        activities_profile: defaultActivitiesProfile,
      });
    }

    return res;
  };

  const fetchClearFormat = async () => {
    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/clear_format`);
    });

    if (res.success) {
      setState({
        activities_profile: defaultActivitiesProfile,
        format: undefined,
        team_role: undefined,
      });
    }

    return res;
  };

  const fetchSubscribeJvach = async () => {
    return await doRequest(() => {
      return instance.get('/api/users/tlgr_bot_link');
    });
  };

  const fetchUnsubscribeJvach = async () => {
    const res = await doRequest(() => {
      return instance.post('/api/users/clear_tlgr_bot_link');
    });

    if (res.success) {
      setState({
        tg_chat_id: undefined,
      });
    }

    return res;
  };

  const fetchAcceptParticipationInvite = async () => {
    const res = await doRequest(() => {
      return instance.post('/api/users/accept_invitation');
    });

    if (res.success) {
      setState({
        invitation: false,
        status: PARTICIPATION_TYPES.PLAYER.name,
      });
    }

    return res;
  };

  const fetchDeclineParticipationInvite = async () => {
    const res = await doRequest(() => {
      return instance.post('/api/users/reject_invitation');
    });

    if (res.success) {
      setState({
        invitation: false,
      });
    }

    return res;
  };

  const fetchLoadTask = async ({ file, video }) => {
    const formData = new FormData();

    if (file) {
      formData.append('solution[file]', file);
    }

    if (video) {
      formData.append('solution[video]', video);
    }

    const res = await doRequest(() => {
      return instance.post('/api/team_activitie/load_task_solution', formData);
    });

    if (res.success) {
      await fetchActivitiesProfile();
    }

    return res;
  };

  const fetchSendCheckTaskSolution = async () => {
    const res = await doRequest(() => {
      return instance.post('/api/team_activitie/send_check_task_solution');
    });

    if (res.success) {
      await fetchActivitiesProfile();
    }

    return res;
  };

  const fetchExpertScoreInfo = async () => {
    const res = await doRequest(
      () => {
        return instance.get(`/api/users/expert_score_info`);
      },
      {},
      null,
      { showError: false },
    );

    if (res.success) {
      setState({ expert_score_info: res.data.data });
    }

    return res;
  };

  const fetchPostAppeal = async (appelText) => {
    const _formData = new FormData();
    _formData.append('task[appeal]', appelText);

    const res = await doRequest(() => {
      return instance.post(`/api/team_activitie/appeal`, _formData);
    });

    if (res.success) {
      setState({
        activities_profile: {
          ...state.activities_profile,
          appeal_send: true,
        },
      });
    }

    return res;
  };

  const toggleJvachBannerVisibility = (visible = !state.jvachBannerVisible) => {
    localStorage.setItem(JvachBannerVisibleLSItemName, visible);
    setState({
      jvachBannerVisible: visible,
    });
  };

  /** Removes invite from state */
  const _removeInvite = (inviteId) => {
    const targetInviteIndex = state.activitiesInvites.findIndex((i) => i.id === inviteId);
    if (targetInviteIndex >= 0) {
      state.activitiesInvites.splice(targetInviteIndex, 1);
      setState({ activitiesInvites: state.activitiesInvites.slice() });
    }
  };

  const setFinishIterTimer = (date) => {
    const dateNow = new Date(new Date().toLocaleString('en-En', { timeZone: 'Europe/Moscow' }));
    const endDate = new Date(date);

    let timeout;

    if (dateNow >= endDate) {
      setState({ isFinishIter: true });
    } else {
      timeout = setTimeout(() => {
        setState({ isFinishIter: true });
      }, endDate - dateNow);
    }

    return timeout;
  };

  const disTeamMember = (id, isChecked) => {
    if (!isChecked) {
      const ids = state.disTeamMembers.filter((it) => it != id);
      setState({ disTeamMembers: ids });
    } else {
      state.disTeamMembers.push(id);
      setState({ disTeamMembers: state.disTeamMembers });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        getCert,
        generateCert,
        logout,
        editUser,
        onChangeSearchField,
        fetchActivities,
        fetchActivitiesProfile,
        fetchSelectActivitiesTask,
        fetchSelectActivitiesRole,
        fetchActivitiesInvites,
        fetchEditTeam,
        fetchAcceptInvite,
        fetchDeclineInvite,
        fetchTeamLeave,
        fetchSelectFormat,
        fetchClearFormat,
        fetchSubscribeJvach,
        fetchUnsubscribeJvach,
        fetchAcceptParticipationInvite,
        fetchDeclineParticipationInvite,
        fetchLoadTask,
        fetchSendCheckTaskSolution,
        disTeamMember,
        fetchPostAppeal,
        fetchExpertScoreInfo,
        toggleJvachBannerVisibility,
        isAuth: state.isAuth,
        name: state.name,
        certificate: state.certificate,
        state,
        loadingSert,
        setLoadingSert,
        checkingOldPassword,
        editPassword,
        setState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
