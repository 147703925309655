import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: 14,
    color: '#fff',
  },
  fontOutlined: {
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  title: {
    position: 'absolute',
    left: 24,
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 1,
  },
  filler: {
    position: 'absolute',
    left: 0,
    top: -1,
    height: '102%',
    width: (props) => `${formatValue(props.item.value) * 10 + 1}%`,
    borderRadius: '0 22px 22px 0',
    boxShadow: (props) => `${props.color} 0px 0px 12px 0px`,
    background: (props) => props.color,
  },
  value: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    right: 24,
    zIndex: 1,
  },
  valueOutlined: {
    color: theme.brandColors.blackGray.main,
  },
}));

const formatValue = (value) => (value < 1 ? 1 : value);
const formatTitle = (title) => `${title[0].toUpperCase()}${title.slice(1).toLowerCase()}`;

export default function ColorLineKodItemSingle(props) {
  const { item, variant } = props;
  const classes = useStyles(props);

  const fontClasses = classnames(classes.font, {
    [classes.fontOutlined]: variant === 'outlined',
  });

  const valueClasses = classnames(fontClasses, classes.value, {
    [classes.valueOutlined]: variant === 'outlined',
  });

  const titleClasses = classnames(fontClasses, classes.title);

  return (
    <>
      <Box className={titleClasses}>{formatTitle(item.title)}</Box>

      <Box className={classes.filler} />

      <Box className={valueClasses}>
        <b>{formatValue(item.value)}</b>
      </Box>
    </>
  );
}
