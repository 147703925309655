import {
  ROLES_SUMMARY_SCHEMA,
  ROLES_SUMMARY_TEST_NAMES,
} from 'models/RolesSummary/RolesSummarySchema';
import { ROLES_SUMMARY_DESCRIPTIONS } from 'models/RolesSummary/RolesSummaryDescriptions';

export class RolesSummary {
  static getScores(tests) {
    let result = [];

    if (!tests || Object.keys(tests).length === 0) {
      return result;
    }

    for (let summarySchemaItem of ROLES_SUMMARY_SCHEMA) {
      const { id, title, testSchema } = summarySchemaItem;
      let score = 0;

      for (let testSchemaItem of testSchema) {
        const { testName, path, factor } = testSchemaItem;

        const { value } = tests[testName].result.variants[1].report.blocks.find(
          (block) => block.type === 'barsDiagram',
        ).blocks[0].scales[path];
        score += value * factor;
      }

      result = [
        ...result,
        { title, score, desc: ROLES_SUMMARY_DESCRIPTIONS.find((d) => d.role_id === id).desc },
      ];
    }

    return result;
  }
}
