import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { LkContext } from 'context/LkContext/LkContext';
import { TestUtils } from 'models/TestUtils/TestUtils';
import ColorLineKodItem from 'components/_testComponents_legacy/ColorLineKod/ColorLineKodItem/ColorLineKodItem';
import { RESULT_BARS_COLOR_PALETTE, TEST_ONLINE } from 'CONSTANTS';
import { makeStyles } from '@material-ui/styles';
import DescriptionsResultTestItem from 'components/DescriptionsResultTest/DescriptionsResultTestItem/DescriptionsResultTestItem';

const useStyles = makeStyles((theme) => ({
  headlineTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  headlineTitleDescription: {
    color: '#717784',
    fontSize: 18,
  },
}));

const GeneralReportHeadlines = (props) => {
  const { tests = [] } = props;

  const classes = useStyles();
  const [state, setState] = useState({
    headlines: [],
  });

  const { headlines } = state;

  useEffect(() => {
    const headlinesConfig = [
      {
        testName: 'tvoia-logika',
        minValue: 7.5,
        ignoreDiagrams: ['S18'],
      },
    ];

    setState({ ...state, headlines: TestUtils.getHeadlines(tests, headlinesConfig) });
  }, [tests]);

  return (
    <Box>
      {headlines.map((headline, i) => {
        const { testName, diagrams } = headline;
        const { generalResultTitle, generalResultDescription } = TEST_ONLINE.find(
          (t) => t.name === testName,
        );

        return (
          <Box key={i} mt={{ xs: '40px' }}>
            <Box className={classes.headlineTitle}>{generalResultTitle}</Box>

            <Box className={classes.headlineTitleDescription} mt={{ xs: '16px' }}>
              {generalResultDescription}
            </Box>

            {diagrams.map((diagram, i) => {
              const { diagramData, scalesText } = diagram;
              const color = RESULT_BARS_COLOR_PALETTE[i % diagrams.length];
              diagramData.title = _formatDiagramDataTile(diagramData.title);

              return (
                <Box key={i}>
                  <ColorLineKodItem
                    forceUnipolar={testName === '20210423-212929-4580'}
                    item={diagramData}
                    variant={'outlined'}
                    color={color}
                  />

                  {scalesText && <DescriptionsResultTestItem item={scalesText} color={color} />}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default GeneralReportHeadlines;

const _formatDiagramDataTile = (title) => {
  return title.replace(/(\(\+\))/g, '');
};
