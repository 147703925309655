import { useContext, useEffect, useMemo, useState } from 'react';
import { LkContext } from 'context/LkContext/LkContext';
import { RolesSummary } from 'models/RolesSummary/RolesSummary';
import { useParams } from 'react-router-dom';
import { instance } from 'api/instance';
import { useProfile } from 'hooks/useProfile';
import { TestUtils } from 'models/TestUtils/TestUtils';

export const useGeneralReport = () => {
  const { id } = useProfile();
  const { user_id } = useParams();
  const { passedTests } = useContext(LkContext);
  const [state, setState] = useState({
    summaryAllowed: true,
    loading: true,
    tests: {},
  });

  const { loading, summaryAllowed, tests } = state;

  useEffect(async () => {
    setState((state) => ({ ...state, loading: true }));

    if (user_id) {
      try {
        const res = await instance.get(`/api/test/user_all_results/${user_id}`);
        const { data } = res;

        let formattedData = {};

        for (let test of data.list) {
          formattedData[test.test_name] = test;
        }

        const summaryAllowed = TestUtils.summaryAllowed(formattedData);

        setState((state) => ({ ...state, tests: formattedData, loading: false, summaryAllowed }));
      } catch {
        setState((state) => ({ ...state, loading: false, summaryAllowed: false }));
      }
    } else {
      if (passedTests) {
        const summaryAllowed = TestUtils.summaryAllowed(passedTests);
        setState((state) => ({ ...state, tests: passedTests, loading: false, summaryAllowed }));
      }
    }
  }, [user_id, passedTests]);

  const testScores = useMemo(() => RolesSummary.getScores(tests), [tests]).filter(
    (testScore) => testScore.score >= 6.5,
  );

  return {
    summaryAllowed,
    loading,
    userId: user_id || id,
    tests,
    testScores,
  };
};
