import React from 'react';
import { Box } from '@material-ui/core';
import {
  VKShareButton,
  FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
} from 'react-share';
import ResultTestShareVkIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestShareVkIcon';
import ResultTestFbIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestFbIcon';
import ResultTestOdnoklassnikiIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestOdnoklassnikiIcon';
import ResultTestTelegrammIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestTelegrammIcon';
import ResultTestTwitterIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestTwitterIcon';
import ResultTestWhatsappIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestWhatsappIcon';
import ResultTestViberIcon from 'components/_testComponents_legacy/ResultTest/ResultTestShare/_images/ResultTestViberIcon';
import { makeStyles } from '@material-ui/styles';
import { important } from 'utils/cssImportant';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexFlow: 'column',
    },
  },
  title: {
    fontSize: 16,
    color: '#717784',
  },
  iconsWrap: {
    display: 'flex',
    '& button': {
      padding: important('0 4px'),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  socialBtn: {
    height: 16,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      '& svg': {
        width: 32,
        height: 32,
      },
    },
  },
}));

const ShareButtons = (props) => {
  const { title, url, imageUrl } = props;

  const classes = useStyles();

  const commonProps = {
    title,
    url,
    round: true,
    className: classes.socialBtn,
  };

  return (
    <Box className={classes.root}>
      <Box>Поделиться: &nbsp;</Box>

      <Box className={classes.iconsWrap}>
        <VKShareButton {...commonProps} image={imageUrl}>
          <ResultTestShareVkIcon />
        </VKShareButton>

        <OKShareButton {...commonProps} image={imageUrl}>
          <ResultTestOdnoklassnikiIcon />
        </OKShareButton>

        <TelegramShareButton {...commonProps}>
          <ResultTestTelegrammIcon />
        </TelegramShareButton>

        <WhatsappShareButton {...commonProps}>
          <ResultTestWhatsappIcon />
        </WhatsappShareButton>

        <ViberShareButton {...commonProps}>
          <ResultTestViberIcon />
        </ViberShareButton>
      </Box>
    </Box>
  );
};

export default ShareButtons;
