import html2pdf from 'html2pdf.js';
import { apiHost } from 'api/instance';

export class PdfUtils {
  static defaultConfig = {
    margin: [20, 20],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'pt', format: 'a4' },
    precision: 10,
  };

  static exportPdf(selector, config) {
    config = { ...this.defaultConfig, ...config };

    return html2pdf().set(config).from(selector).save();
  }

  static getPfdLink(fileName, fileLabel) {
    const params = new URLSearchParams();
    params.append('name', fileLabel);
    params.append('file', fileName);
    params.append('jwt', localStorage.getItem('JWT'));

    return `${apiHost}/api/document/download_file?${params}`;
  }
}
