import React from 'react';
import classes from './HeaderDialog.module.scss';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../_redux/store';
import { AuthenticationStatus } from '../../_constants/authentication-status';
import { useProfileQuery } from '../../_services/usersApi/usersApi';
import { PermissionUtils } from '../../models/PermissionUtils';
import { UserRoleId } from '../../_types/UserRoleId';
import { useVisuallyImpariedMode } from 'features/visuallyImpairedMode';
import { ReactComponent as VkIcon } from 'pages/LandingPage/assets/icons/vk-icon.svg';
import { ReactComponent as TgIcon } from 'pages/LandingPage/assets/icons/telegram-icon.svg';
import classNames from 'classnames';
import { Icon } from '../../shared/Icon/Icon';

const HeaderDialog = (props) => {
  const { onLinkClick } = props;
  const userProfile = { ...useProfileQuery().data };
  const { authenticationStatus } = useSelector((rootState: RootState) => rootState.app);
  const isAdmin = PermissionUtils.hasRole(UserRoleId.ADMIN, userProfile);

  const { isActive } = useVisuallyImpariedMode();

  return (
    <Dialog
      fullScreen
      {...props}
      classes={{
        paperFullScreen: classes.paperFullScreen,
        scrollPaper: classes.root,
        root: classNames({ [classes.vim]: isActive }),
      }}
    >
      <nav className={classes.navbar}>
        <div className={classes.actions}>
          <div className={classes.socialIcons}>
            <Icon Svg={VkIcon} className={classes.vkIcon} />
            <Icon Svg={TgIcon} className={classes.tgIcon} />
          </div>
          <IconButton className={classes.iconBtn} onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      </nav>
      <div className={classes.mainLinks}>
        <nav>
          <ul className={classes.headerDialogOptions}>
            <li>
              <a onClick={onLinkClick} href='#aboutUsSection' className={classes.headerDialogLink}>
                О НАС
              </a>
            </li>
            <li>
              <a
                onClick={onLinkClick}
                href='#whoCanBecomeSection'
                className={classes.headerDialogLink}
              >
                ДЛЯ КОГО
              </a>
            </li>
            <li>
              <a onClick={onLinkClick} href='#footerSection' className={classes.headerDialogLink}>
                КОНТАКТЫ
              </a>
            </li>
          </ul>
        </nav>
        {authenticationStatus === AuthenticationStatus.AUTHORIZED ? (
          <div className={classes.btnsContainer}>
            {!isAdmin && (
              <Link to={'/lk'}>
                <Button className={classes.personalBtn}>Личный кабинет</Button>
              </Link>
            )}
          </div>
        ) : (
          <div className={classes.btnsContainer}>
            <Link to={'/auth/signin'} onClick={onLinkClick}>
              <Button className={classes.loginBtn}>Вход</Button>
            </Link>
            <Link to={'/auth/signup'} onClick={onLinkClick}>
              <Button className={classes.registrationBtn}>Регистрация</Button>
            </Link>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default HeaderDialog;
