import React from 'react';

const ColorLineKodPlusIcon = (props) => {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='9.57862' cy='9.63136' r='9.41456' fill='white' />
      <rect x='9.0791' y='5.13135' width='1' height='9' fill='#39DEC8' />
      <rect
        x='14.0791'
        y='9.13135'
        width='1'
        height='9'
        transform='rotate(90 14.0791 9.13135)'
        fill='#39DEC8'
      />
    </svg>
  );
};

export default ColorLineKodPlusIcon;
