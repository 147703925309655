import React from 'react';
import { useColorLineKodItemStyles } from './style';
import ColorKineKodItemBipolar from './ColorLineKodItemBipolar/ColorKineKodItemBipolar';
import ColorLineKodItemSingle from './ColorLineKodItemSingle/ColorLineKodItemSingle';
import classnames from 'classnames';

export default function ColorLineKodItem(props) {
  const { item, forceUnipolar, variant } = props;
  const classes = useColorLineKodItemStyles(props);

  const rootClasses = classnames(classes.lineWrap, {
    [classes.lineWrapOutlined]: variant === 'outlined',
  });

  return (
    <div className={rootClasses}>
      {!forceUnipolar && item.bipolar ? (
        <ColorKineKodItemBipolar {...props} />
      ) : (
        <ColorLineKodItemSingle {...props} />
      )}
    </div>
  );
}
