import { makeStyles } from '@material-ui/core';

export const useColorLineKodItemStyles = makeStyles((theme) => ({
  lineWrap: {
    height: 54,
    boxSizing: 'content-box',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#3F3F3F',
    borderRadius: 22,
    marginBottom: 16,
    position: 'relative',
    border: '4px solid',
    borderColor: '#fff',
    overflow: 'hidden',
  },
  lineWrapOutlined: {
    borderColor: theme.brandColors.brandGray.main,
    background: '#fff',
  },
}));
