import { useCallback, useMemo, useState } from 'react';

export const useDialog = ({ defaultOpen = false } = {}) => {
  const [state, setState] = useState({
    open: defaultOpen,
  });

  const onClose = useCallback(() => {
    setState((prev) => ({ ...prev, open: false }));
  }, []);

  const open = useCallback(() => {
    setState((prev) => ({ ...prev, open: true }));
  }, []);

  const toggle = useCallback((open) => {
    setState((prev) => ({ ...prev, open: open ?? !prev.open }));
  }, []);

  const dialogProps = useMemo(() => {
    return {
      open: state.open,
      onClose,
    };
  }, [state.open, onClose]);

  return {
    dialogProps,
    toggle,
    open,
    close: onClose,
  };
};
