import React from 'react';
import classes from './Link.module.scss';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ReactComponent as ArrowRight } from 'images/ArrowRight.svg';

export interface LinkProps extends RouterLinkProps {
  variant?: 'underline';
  color?: 'red' | 'green' | 'blue';
  arrow?: boolean;
  keepQuery?: boolean;
  disabled?: boolean;
}

const Link = (props: LinkProps) => {
  const {
    to,
    arrow = true,
    variant,
    color = 'red',
    keepQuery,
    children,
    disabled,
    ...routerLinkProps
  } = props;
  const { search } = useLocation();

  const className = classnames({
    [props.className]: true,
    [classes.root]: true,
    [classes.variantUnderline]: variant === 'underline',
    [classes.variantUnderline_colorRed]: variant === 'underline' && color === 'red',
    [classes.variantUnderline_colorBlue]: color === 'blue',
    [classes.disabled]: disabled,
  });

  return (
    <RouterLink {...routerLinkProps} to={keepQuery ? to + search : to} className={className}>
      {children}

      {variant === 'underline' && arrow && <ArrowRight className={classes.arrow} />}
    </RouterLink>
  );
};

export default Link;
