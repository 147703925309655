import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import classes from './style.module.scss';
import { Icon } from 'shared/Icon/Icon';
import { ReactComponent as SmallLightningICon } from './assets/lightning.svg';

const HEADER_VARIANTS = ['h1', 'h2', 'h3', 'h4'] as const;

const VARIANT_COMPONENT_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  bodyL: 'p',
  bodyM: 'p',
  bodyS: 'p',
  bodyXl: 'p',
  subtitleL: 'p',
  subtitleS: 'p',
} as const;

export type TypographyProps = HTMLAttributes<HTMLHeadingElement> & {
  component?: React.ElementType;
  variant:
    | (typeof HEADER_VARIANTS)[number]
    | 'bodyL'
    | 'bodyM'
    | 'bodyS'
    | 'bodyXl'
    | 'subtitleL'
    | 'subtitleS';
  headerVariant?: 'Vasek' | 'Grandis';
  color?: 'grey' | 'black' | 'red' | 'blue' | 'orange' | 'green' | 'white';
  mb?: string | number;
  lightning?: boolean;
  fontWeight?: number;
  fontSize?: string;
  textAlign?: 'center';
};

const Typography = (props: TypographyProps) => {
  const {
    component,
    className,
    variant,
    headerVariant = 'Vasek',
    color,
    mb,
    lightning,
    children,
    fontWeight,
    textAlign,
    fontSize,
    ...rest
  } = props;

  const Component = component || VARIANT_COMPONENT_MAPPING[variant];

  return (
    <Component
      style={{ marginBottom: mb, fontWeight: fontWeight, textAlign, fontSize }}
      className={clsx(className, classes.root, {
        [classes[`variant-${variant}`]]: Boolean(variant),
        [classes[`headerVariant-${headerVariant}`]]:
          !variant.includes('body') && !variant.includes('subtitle') && Boolean(headerVariant),
        [classes[`color-${color}`]]: Boolean(color),
        [classes.lightning]: lightning,
      })}
      {...rest}
    >
      {children}
      {/* Важный момент в lightning.svg. Был сделан атрибут preserveAspectRatio=none для отключения соотношения сторон */}
      {lightning && <Icon Svg={SmallLightningICon} className={classes.lightIcon} />}
    </Component>
  );
};

export default Typography;
