import React from 'react';
import { Box } from '@material-ui/core';
import BrandTypography from 'shared/BrandTypography/BrandTypography';
import PrintButton from 'shared/PrintButton/PrintButton';
import { useGeneralReportStyles } from 'components/_testComponents_legacy/GeneralReport/style';
import ShareButtons from 'shared/ShareButtons/ShareButtons';
import LogoSrc from './_images/logo.png';
import GeneralReportRoles from 'components/_testComponents_legacy/GeneralReport/GeneralReportRoles';
import GeneralReportHeadlines from 'components/_testComponents_legacy/GeneralReport/GeneralReportHeadlines';
import { PdfUtils } from 'models/PdfUtils/PdfUtils';
import { useGeneralReport } from 'components/_testComponents_legacy/GeneralReport/_hooks/useGeneralReport';
import { TestUtils } from 'models/TestUtils/TestUtils';
import { Redirect, useNavigate, useLocation } from 'react-router-dom';
import Button from 'shared/Button/Button';

const { protocol, hostname, port } = window.location;

const imageUrl = `${protocol}//${hostname}${port && `:${port}`}/test-social.png`;

const getShareUrl = (userId) => {
  return `${protocol}//${hostname}${port && `:${port}`}/results/summary/${userId}`;
};

const GeneralReport = () => {
  const classes = useGeneralReportStyles();
  const navigate = useNavigate();
  const { userId, tests, testScores, loading, summaryAllowed } = useGeneralReport();

  if (!summaryAllowed) {
    navigate(-1);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box>
          <BrandTypography variant={'pageTitle'}>Итоговый отчет</BrandTypography>

          <ShareButtons
            title={
              'Разбираюсь в своих предрасположенностях вместе с тестами от «Твой Ход». Узнай и ты больше о себе: https://tvoyhod.online'
            }
            url={getShareUrl(userId)}
            imageSrc={imageUrl}
          />
        </Box>

        <Box className={classes.headerButtons}>
          <PrintButton />

          <Button
            onClick={() =>
              PdfUtils.exportPdf(document.querySelector('#page'), { filename: 'Итоговый отчет' })
            }
          >
            Сохранить
          </Button>
        </Box>
      </Box>

      <Box className={classes.rolesWrap}>
        <Box className={classes.rolesTitleWrap}>
          <Box className={classes.rolesTitleLogo}>
            <img src={LogoSrc} />
          </Box>

          <BrandTypography className={classes.rolesTitle} fontSize={'24px'}>
            <b>Командные роли</b>
          </BrandTypography>

          <BrandTypography className={classes.rolesTitleDesc}>
            <p>
              В командной работе все мы занимаем определенные роли. Причем в разных коллективах они
              могут быть совсем разными. Хочешь узнать, какая роль соответствует твоему характеру,
              мотивам и интересам? Смотри результаты!
            </p>
            <p>
              Здесь ты получишь описания ролей, которые больше всего тебе подходят. А если описаний
              нет — скорее всего, ты пока еще не определился с этим вопросом. Ничего страшного,
              пробуй и ищи! Не бойся ошибиться или зайти в тупик.
            </p>
          </BrandTypography>
        </Box>

        <GeneralReportRoles testScores={testScores} />
      </Box>

      <Box mt={{ xs: '40px', md: '64px' }}>
        <GeneralReportHeadlines tests={tests} />
      </Box>
    </Box>
  );
};

export default GeneralReport;
