export const ROLES_SUMMARY_DESCRIPTIONS = [
  {
    role_id: 0,
    desc: (
      <p>
        Ты — настоящий Генератор идей, главный источник креатива в команде. У тебя богатое
        воображение (возможно, иногда даже слишком ), ты можешь смотреть на вещи с неожиданной
        стороны, находить свежие и оригинальные решения. Но что если твои творческие способности не
        востребованы в проекте? Ты теряешь к нему интерес и ищешь что-то другое. Лови лайфхак: веди
        блокнот с креативными идеями — они пригодятся тебе не в этом проекте, так в следующем.
        Уделяй больше внимания тщательной проработке идей. Учись вникать в детали и планировать. Это
        важно, чтобы твои проекты успешно воплотились в жизнь.
      </p>
    ),
  },
  {
    role_id: 1,
    desc: (
      <p>
        Твоя роль в команде — Исследователь ресурсов. У тебя хорошо получается находить новые
        возможности и полезных людей. Твоя сильная сторона — умение разбираться в деталях и собирать
        недостающие кусочки пазла. Поверь, такое далеко не многим под силу! Но тебе быстро
        становится скучно, если долго нет ничего нового. Попробуй переключиться, сменить
        деятельность и вести дневник находок. Твой ум нуждается в новой информации, поэтому
        продолжай находить людей и новые факты. Просто в другой области — например, в сфере хобби.
        Иногда нужно и отдохнуть!
      </p>
    ),
  },
  {
    role_id: 3,
    desc: (
      <p>
        Ты — Специалист, который в проекте отвечает за профессионализм. Ты любишь разбираться в теме
        во всех подробностях и поражаешь окружающих глубиной познаний. Правда, иногда ты слишком
        увлекаешься и теряешь общую картину. Кроме того, внимательность к деталям и стремление
        учесть все может сказаться на темпе работы. Не расстраивайся — окружающие ценят твою
        преданность (а иногда и помешанность, давай будем честны ) любимой теме. Но прокачать навык
        ускоренной работы в любом случае не помешает!
      </p>
    ),
  },
  {
    role_id: 2,
    desc: (
      <p>
        Ты — Аналитик, беспристрастный и рассудительный. Твоя сильная сторона — умение выбирать
        оптимальные решения. Твое мнение ценится как самое объективное, и на него практически нельзя
        повлиять (разве что аргументами). Проблема в том, что постоянная работа ума может утомлять,
        даже если ты сам этого не замечаешь. Чтобы взгляд не замыливался, а ум оставался острым, как
        швейцарский нож, делай небольшие передышки и переключайся на другие задачи. Особенно полезно
        заняться спортом 😉 А потом — с новыми силами в бой!
      </p>
    ),
  },
  {
    role_id: 6,
    desc: (
      <p>
        Ты — всегда собранный Координатор. Без тебя в проекте наступит хаос, потому что именно ты
        поддерживаешь в нем порядок. Кто другой может всем все объяснить и напомнить, распределить
        задачи так, чтобы все остались довольны? Ты все держишь под контролем — знаешь, что делать,
        если кто-то тормозит. Но ты привык организовывать других, а не делать сам. Не забывай и о
        своих задачах, а то заскучаешь 😄
      </p>
    ),
  },
  {
    role_id: 5,
    desc: (
      <p>
        Поздравляем, ты — Мотиватор! Твоя задача — сделать так, чтобы проект взлетел, как ракета.
        Пока остальные сомневаются и перебирают варианты, ты уже видишь готовое решение и умеешь
        заразить других своим оптимизмом Правда, твой постоянный позитив иногда мешает видеть
        проблемы других людей. Попробуй обращать больше внимания на настроения и тревоги окружающих
        — может быть, они не такие уж безосновательные, как кажется на первый взгляд.
      </p>
    ),
  },
  {
    role_id: 4,
    desc: (
      <p>
        Да ты же самая настоящая Душа команды! Ты умеешь настраивать эмоциональный комфорт на
        проекте и разруливать конфликты. Тебя ценят за то, что ты хорошо чувствуешь других людей и
        можешь к каждому найти подход. Но есть и обратная сторона медали: случись какой-то кризис,
        ты можешь растеряться. Тебе будет трудно быстро принять непопулярное, но необходимое
        решение. Прокачивай стрессоустойчивость, и все будет в шоколаде 🍫
      </p>
    ),
  },
  {
    role_id: 7,
    desc: (
      <p>
        В свой команде ты — Реализатор. Без твоего участия все развалится. Пока другие суетятся и
        выясняют отношения, ты просто делаешь свою работу, и это дает результат! С тобой надежно и
        спокойно, как за каменной стеной Но вот представь, что наступило время перемен — все
        приходится делать с нуля. Тебе будет сложновато перестроиться. Поэтому старайся прокачивать
        адаптивность и всегда внутренне будь готов к изменениям.
      </p>
    ),
  },
  {
    role_id: 8,
    desc: (
      <p>
        Твоя роль в команде — Контролер. Ошибки, сбои и недочеты в работе не имеют ни единого шанса,
        если ты в деле. Ты устраняешь все препятствия на пути к успеху. Ты добросовестный и
        ответственный, ты помнишь все — задачи, этапы, сроки — и следишь за тем, чтобы их соблюдали.
        Но твоя требовательность может напрягать окружающих. Попробуй относиться к ним немного
        проще. В конце концов, они тоже люди 😅
      </p>
    ),
  },
];
