import { Box } from '@material-ui/core';
import ColorLineKodPlusIcon from 'components/_testComponents_legacy/ColorLineKod/_images/ColorLineKodPlusIcon';
import ColorLineKodMinusIcon from 'components/_testComponents_legacy/ColorLineKod/_images/ColorLineKodMinusIcon';
import React from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const specialChars = [
  {
    regex: /(\(\+\))/,
    component: (
      <Box display={'flex'} padding={'0 8px'}>
        <ColorLineKodPlusIcon />
      </Box>
    ),
  },
  {
    regex: /(\(-\))/,
    component: (
      <Box display={'flex'} padding={'0 8px'}>
        <ColorLineKodMinusIcon />
      </Box>
    ),
  },
];

export const formatString = (string = '', config = specialChars) => {
  const splitRegExp = config.reduce((accum, next) => {
    return new RegExp(accum.source + '|' + next.regex.source, 'g');
  }, config[0].regex);

  const parts = string.split(splitRegExp);

  for (let i = 0; i < parts.length; i++) {
    if (parts[i]) {
      const configItem = config.find((c) => parts[i]?.match(c.regex));

      if (configItem) {
        parts[i] = configItem.component;
      } else {
        parts[i] = capitalizeFirstLetter(parts[i]);
      }
    }
  }

  return parts;
};

export const isBipolarSingleLabel = (titleMin, titleMax) => {
  const regexp = /\(\+\)|\(-\)/;

  return titleMin.match(regexp) && titleMax.match(regexp);
};
